import HeaderPartner from './components/header-partner';
import HeaderBalances from './components/header-balances';

const Report = () => {
  return (
    <>
      <HeaderPartner />
      <HeaderBalances />
    </>
  );
};

export default Report;
