import { WithTranslation, withTranslation } from 'react-i18next';
import * as S from './styles';
import { ReactComponent as CopySvg } from 'images/multtiplo/copy.svg';
import QRCode from 'qrcode.react';
import { BalanceLabel, BalancesContainer, ValueBalance } from 'features/admin/dashboard/screens/my-account/styles';
import StringUtils from 'shared/util/string-utils';

interface Props extends WithTranslation {
  cancel?: boolean;
  qrCodeUrl: string;
  pixKey: string;
  valueAdd: number;
  assignmentFee: string;
  amountPay: number;
}

const PendingPayment = ({ cancel, pixKey, qrCodeUrl, valueAdd, assignmentFee, amountPay, t }: Props) => {
  return (
    <S.Container>
      <S.InputsContainer>
        <S.Title>{t('admin.newBalance.pendingPayment.title')}</S.Title>
        <BalancesContainer>
          <BalanceLabel>
            <span>{t('admin.myAccount.recentBalance')}</span>
            <ValueBalance>{StringUtils.moneyMaskPtbr(valueAdd)}</ValueBalance>
          </BalanceLabel>
          <BalanceLabel>
            <span>{t('admin.myAccount.sessionTaxes')}</span>
            <ValueBalance>{Number(assignmentFee)?.toLocaleString('pt-BR') + ' %'}</ValueBalance>
          </BalanceLabel>
          <BalanceLabel>
            <span>{t('admin.myAccount.balanceToBerelease')}</span>
            <ValueBalance>{StringUtils.moneyMaskPtbr(amountPay)}</ValueBalance>
          </BalanceLabel>
        </BalancesContainer>
      </S.InputsContainer>

      {!cancel && (
        <S.QrCodeContainer>
          <S.Text>{t('admin.newBalance.pendingPayment.scanQrCode')}</S.Text>
          <iframe style={{ width: '200px', height: '200px', zoom: '2.75' }} src={qrCodeUrl} />
          <S.Text>{pixKey}</S.Text>
          <S.Button onClick={() => navigator.clipboard.writeText(pixKey)}>
            <CopySvg />
            {t('admin.newBalance.pendingPayment.copyCode')}
          </S.Button>
        </S.QrCodeContainer>
      )}
    </S.Container>
  );
};

export default withTranslation()(PendingPayment);
