import { FinancingStatusBalance } from 'model/enums/financing-status-balance';
import { RoutePath } from 'model/enums/route-path';
import { PaymentInfo } from 'model/my-account';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import myAccountService from 'services/my-account-service';
import { useTheme } from 'styled-components';

interface LocationParameters {
  state: {
    paymentId: string;
    assignmentFee: string;
  };
}

const useNewBalanceScreen = () => {
  const { color } = useTheme();
  const history = useHistory();
  const { state } = useLocation<LocationParameters>();

  const [isShowPaymentCancellationModal, setIsShowPaymentCancellationModal] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>();
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>();

  useMemo(() => {
    const isEssentialParameterMissing = state.state?.paymentId == null;

    if (isEssentialParameterMissing) {
      history.push(RoutePath.ADMIN_HOME);
    }
  }, [state]);

  const goToPreviousScreen = () => {
    history.push('minha-conta');
  };

  useEffect(() => {
    getPixInfo();
  }, []);

  useEffect(() => {
    if (paymentInfo?.paymentStatus != FinancingStatusBalance.FINISHED) {
      const timer = setTimeout(async () => {
        getPixInfo();
      }, 13000);

      return () => clearTimeout(timer);
    }
  }, [paymentInfo]);

  const getPixInfo = async () => {
    await myAccountService.getpaymentInfo(state.state.paymentId).then(async res => {
      setPaymentInfo(res);
      switch (res.paymentStatus) {
        case FinancingStatusBalance.GENERATING_PAYMENT:
          setCurrentStep(0);
          break;
        case FinancingStatusBalance.WAITING_PAYMENT:
          setCurrentStep(1);
          break;
        case FinancingStatusBalance.TRANSFER_BMP:
          setCurrentStep(2);
          break;
        case FinancingStatusBalance.TRANSFER_NELLPAY_IUGU:
          setCurrentStep(2);
          break;
        case FinancingStatusBalance.TRANSFER_NELLPAY_IUGU_CONFIRMATION:
          setCurrentStep(3);
          break;
        case FinancingStatusBalance.TRANSFER_NELLPAY_GIRO:
          setCurrentStep(4);
          break;
        case FinancingStatusBalance.TRANSFER_NELLPAY_GIRO_CONFIRMATION:
          setCurrentStep(5);
          break;
        case FinancingStatusBalance.FINISHED:
          setCurrentStep(6);
          break;

        default:
          break;
      }
    });
  };

  const openCancelPaymentModal = () => {
    setIsShowPaymentCancellationModal(true);
  };

  const closeCancelPaymentModal = () => {
    setIsShowPaymentCancellationModal(false);
  };

  const handleCancelPayment = async () => {
    await myAccountService.cancelTransaction(state.state.paymentId).then(res => window.location.reload());
    closeCancelPaymentModal();
  };

  return {
    color,
    state,
    isShowPaymentCancellationModal,
    currentStep,
    paymentInfo,
    goToPreviousScreen,
    handleCancelPayment,
    openCancelPaymentModal,
    closeCancelPaymentModal,
  };
};

export default useNewBalanceScreen;
