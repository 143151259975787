import { Authority } from 'model/enums/authority';
import { Installment } from 'model/installments';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiDownload } from 'react-icons/fi';
import ReactLoading from 'react-loading';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IRootState } from 'reducer';
import financingService from 'services/financing-service';
import StringUtils from 'shared/util/string-utils';
import useResponsiveMobile from 'shared/util/use-responsive-hook';
import { hasAuthorities } from 'shared/util/utils';
import { useTheme } from 'styled-components';
import { TabPayments } from '../customerAnalysis/components/tabPayments';
import { Status } from '../dashboard/components/status';
import { TableList, TableValues } from '../dashboard/components/table-list';
import { Desktop } from './layout/desktop';
import { Mobile } from './layout/mobile';
import { StatusContainer, StyledLoadingContainer } from './styles';

export const PaymentScreen = () => {
  const isMobile = useResponsiveMobile().isTablet;
  const { t } = useTranslation();
  const { financingId } = useParams<{ financingId: string }>();
  const { color } = useTheme();
  const [tabSelected, setTabSelected] = useState(1);
  const [currentPage, setCurrentPage] = useState('1');
  const [totalPages, setTotalPages] = useState<null | number>(1);
  const [page, setPage] = useState(0);
  const [installments, setInstallments] = useState<Installment[]>([]);
  const [totalInstallments, setTotalInstallments] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [idLastConfirmedInstallment, setIdLastConfirmedInstallment] = useState<number | null>(null);
  const account = useSelector((state: IRootState) => state.authentication.account);

  // tslint:disable-next-line:no-shadowed-variable
  const getInstallments = async (page?: number) => {
    if (financingId) {
      if (tabSelected === 1) {
        const res = await financingService.getFinancingInstallments(Number(financingId), 5, page, 'PENDING');
        res.totalPages === 0 ? setTotalPages(1) : setTotalPages(res.totalPages);
        setInstallments(res.content);
        setPage(res.pageable.pageNumber);
      } else if (tabSelected === 2) {
        const res = await financingService.getFinancingInstallments(Number(financingId), 5, page, 'PAID');
        res.totalPages === 0 ? setTotalPages(1) : setTotalPages(res.totalPages);
        setInstallments(res.content);
        setPage(res.pageable.pageNumber);
      }
    }
  };

  const getTotalInstallments = async () => {
    if (financingId) {
      const res = await financingService.getFinancingById(Number(financingId));
      setTotalInstallments(res?.installmentsTotal ?? 0);
    }
  };

  const getMeat = async () => {
    await financingService.getMeat(Number(financingId)).then(response => {
      window.open(response.presignedUrl, '_blank');
    });
  };

  const handleConfirmPayment = (instalmentId?: number) => {
    if (instalmentId != null) {
      setIdLastConfirmedInstallment(instalmentId);

      financingService
        .confirmPayment(instalmentId)
        .then(() => getInstallments())
        .finally(() => {
          setIdLastConfirmedInstallment(null);
        });
    }
  };

  const setSuffix = (filename?: string) => {
    if (filename) {
      if (filename.includes('.pdf')) {
        return filename;
      }

      return `${filename}.pdf`;
    }

    return '';
  };

  const downloadBankSlip = (attachUrl?: string, installment?: Installment) => {
    if (attachUrl && installment) {
      fetch(attachUrl)
        .then(res => res.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', setSuffix(installment.attachment?.fileName));
          document.body.appendChild(link);
          link.click();
          if (link?.parentNode) {
            link?.parentNode?.removeChild(link);
          }
        });
    }
  };

  useEffect(() => {
    getInstallments();
    getTotalInstallments();
  }, [tabSelected]);

  const setTableValues = (tab: number): TableValues[] => {
    if (tab === 2) {
      return [
        {
          header: t('admin.paymentScreen.headers.installment'),
          values: installments.map(installment => `${installment.installmentNumber?.toString()}ª de ${totalInstallments}`),
        },
        {
          header: t('admin.paymentScreen.headers.value'),
          values: installments.map(installment => StringUtils.moneyMaskPtbr(installment.installmentValue)),
        },
        {
          header: t('admin.paymentScreen.headers.dueDate'),
          values: installments.map(installment => StringUtils.dateFormatMask(installment.dueDate)),
        },
        {
          header: t('admin.paymentScreen.headers.paymentDate'),
          values: installments.map(installment => StringUtils.dateFormatMask(installment?.paymentDate ?? '')),
        },
        {
          header: t('admin.paymentScreen.headers.status'),
          values: installments.map((installment, idx) => (
            <StatusContainer key={installment.id ?? idx}>
              <Status text={t(`enums.installment.${installment.status}`)} color={installment.status ?? ''} />
              <button
                onClick={() => {
                  if (installment.attachment?.presignedUrl != null) {
                    downloadBankSlip(installment.attachment.presignedUrl ?? '', installment);
                  } else {
                    setOpenModal(true);
                  }
                }}
              >
                <FiDownload size={20} color={color.primaryColor} />
                <span>{t('admin.paymentScreen.downloadButton')}</span>
              </button>
            </StatusContainer>
          )),
          fullLength: true,
        },
      ];
    } else {
      return [
        {
          header: t('admin.paymentScreen.headers.installment'),
          values: installments.map(installment => `${installment.installmentNumber?.toString()}ª de ${totalInstallments}`),
        },
        {
          header: t('admin.paymentScreen.headers.value'),
          values: installments.map(installment => StringUtils.moneyMaskPtbr(installment.installmentValue)),
        },
        {
          header: t('admin.paymentScreen.headers.dueDate'),
          values: installments.map(installment => StringUtils.dateFormatMask(installment.dueDate)),
        },
        {
          header: t('admin.paymentScreen.headers.status'),
          values: installments.map((installment, idx) => (
            <StatusContainer key={installment.id ?? idx} style={{ height: '24px' }}>
              <Status text={t(`enums.installment.${installment.status}`)} color={installment.status ?? ''} />
              {hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN]) && (
                <button onClick={() => handleConfirmPayment(installment.id)} disabled={idLastConfirmedInstallment === installment.id}>
                  {idLastConfirmedInstallment === installment.id ? (
                    <StyledLoadingContainer>
                      <ReactLoading type="spinningBubbles" color={color.primaryColor} />
                    </StyledLoadingContainer>
                  ) : (
                    <span>{t('admin.paymentScreen.confirmPayment')}</span>
                  )}
                </button>
              )}
            </StatusContainer>
          )),
          fullLength: true,
        },
        {
          header: '',
          values: installments.map((installment, idx) => (
            <StatusContainer key={installment.id ?? idx} style={{ marginRight: '15px' }}>
              <button
                onClick={() => {
                  if (installment.attachment?.presignedUrl != null) {
                    downloadBankSlip(installment.attachment.presignedUrl ?? '', installment);
                  } else {
                    setOpenModal(true);
                  }
                }}
              >
                <FiDownload size={20} color={color.primaryColor} />
                <span>{t('admin.paymentScreen.downloadButton')}</span>
              </button>
            </StatusContainer>
          )),
        },
      ];
    }
  };

  const getTabContent = () => {
    if (tabSelected === 1) {
      return <TableList tableValues={setTableValues(1)} internal noPagination />;
    }

    if (tabSelected === 2) {
      return <TableList tableValues={setTableValues(2)} internal noPagination />;
    }

    if (tabSelected === 3) {
      return <TabPayments showStats={false} />;
    }
  };

  const onHandleChangePage = (value: string) => {
    const newPage = Number(value);

    if (Number.isNaN(newPage)) {
      return;
    }

    if (newPage === 0) {
      return setCurrentPage('');
    }

    if (totalPages && newPage > totalPages) {
      if (page != null) {
        return setCurrentPage((page + 1).toString());
      }
    }

    setCurrentPage(newPage.toString());
    getInstallments(newPage - 1);
  };

  const onHandleClickLeft = () => {
    const newPage = Number(currentPage) - 1;

    if (newPage >= 1) {
      setCurrentPage(newPage.toString());
      getInstallments(newPage - 1);
    }
  };

  const onHandleClickRight = () => {
    const newPage = Number(currentPage) + 1;

    if (totalPages && newPage <= totalPages) {
      setCurrentPage(newPage.toString());
      getInstallments(newPage - 1);
    }
  };

  useEffect(() => {
    onHandleChangePage(currentPage);
  }, [currentPage]);

  return !isMobile ? (
    <Desktop
      onHandleClickLeft={onHandleClickLeft}
      onHandleClickRight={onHandleClickRight}
      onHandleChangePage={onHandleChangePage}
      getTabContent={getTabContent}
      openModal={openModal}
      setOpenModal={setOpenModal}
      tabSelected={tabSelected}
      setTabSelected={setTabSelected}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      totalPages={totalPages}
      getInstallments={getInstallments}
      getMeat={getMeat}
    />
  ) : (
    <Mobile
      downloadBankSlip={downloadBankSlip}
      tabSelected={tabSelected}
      setTabSelected={setTabSelected}
      installments={installments}
      idLastConfirmedInstallment={idLastConfirmedInstallment}
      handleConfirmPayment={handleConfirmPayment}
      getMeat={getMeat}
    />
  );
};
