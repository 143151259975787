import axios from 'axios';
import { Address, AddressLP, AddressSimulator, AddressViaCep } from 'model/address';
import { ClientLP } from 'model/client';
import { SimulatorType } from 'model/enums/simulator-lp-properties';
import { SimulatorFormData, State } from 'model/landing-page';
import { unMaskedCurrency } from 'shared/util/register-utils';
import { SimulatorError } from './simulator-exception';

export enum SimulatorSteps {
  DATA,
  ADDRESS,
}

export class SimulatorHandler {
  defaultSteps: SimulatorSteps[] = [SimulatorSteps.DATA, SimulatorSteps.ADDRESS];

  process = async (
    data: SimulatorFormData,
    type: SimulatorType,
    isPhysicalPerson: boolean,
    setAddressError: (value: string) => void,
    validStates: State[],
    setHasMissingFields: (value: boolean) => void
  ): Promise<ClientLP> => {
    let simulatorSteps: SimulatorSteps[];

    simulatorSteps = this.defaultSteps;

    return await this.processSteps(data, simulatorSteps, isPhysicalPerson, setAddressError, validStates, setHasMissingFields);
  };

  processAddress = async (
    zipCode?: string,
    setAddressError?: (value: string) => void,
    validStates?: State[],
    data?: SimulatorFormData,
    setHasMissingFields?: (value: boolean) => void
  ): Promise<AddressSimulator> => {
    let address: AddressSimulator = {
      street: '',
      zipcode: '',
      district: '',
      uf: '',
      city: {
        name: '',
      },
    };

    const res = await axios.get<AddressViaCep>(`https://viacep.com.br/ws/${zipCode}/json/`);
    if (validStates?.length !== 0) {
      const checkState = validStates?.some(state => state.acronym.toUpperCase().includes(res.data.uf?.toUpperCase() ?? ''));
      if (!checkState) {
        throw new SimulatorError(SimulatorType.OUT_OF_ZONE);
      }
    }

    if (setAddressError) {
      if (res.data.erro || !res.data.cep) {
        setAddressError('CEP inválido');
      } else {
        setAddressError('');
      }
    }

    const hasStreet = res.data.logradouro ? res.data.logradouro : data?.street;
    const hasDistrict = res.data.bairro ? res.data.bairro : data?.district;
    const hasZipCode = res.data.cep && res.data.cep;

    if (!hasZipCode) {
      throw new Error('CEP inválido');
    }

    if (!hasDistrict || !hasStreet) {
      setHasMissingFields?.(true);
      throw new Error('Missing required fields');
    }

    address.street = hasStreet;
    address.zipcode = hasZipCode;
    address.district = hasDistrict;
    address.city = { name: res.data.localidade };
    address.uf = res.data.uf;

    if (data?.addressNumber) {
      address.number = data.addressNumber;
    }

    return Promise.resolve(address);
  };

  processData = async (client: ClientLP, data: SimulatorFormData, isPhysicalPerson: boolean) => {
    if (isPhysicalPerson) {
      let newDate: string | undefined;

      if (data.birth) {
        const date = data.birth.split('/');
        newDate = `${date[2]}-${date[1]}-${date[0]}`;
      }
      client.phone = data.phone;
      client.email = data.email;
      client.physicalPerson = {
        name: data?.name,
        cpf: data?.cpf,
        birth: newDate,
      };

      if (data.income) {
        client.physicalPerson.income = unMaskedCurrency(data.income) / 100;
      }
      if (data.employmentBond) {
        client.employmentBond = data.employmentBond;
      }

      if (data.gender) {
        client.physicalPerson.genderType = data.gender;
      }

      if (data.maritalStatus?.id) {
        client.physicalPerson.maritalStatus = {
          id: data.maritalStatus?.id,
        };
      }
      if (data.organizationSubsidiary?.id) {
        client.organizationSubsidiary = { id: data.organizationSubsidiary.id };
      }

      if (data.occupation != null) {
        client.physicalPerson.occupation = data.occupation;
      }
    } else {
      client.phone = data?.phone;
      client.email = data?.email;
      client.legalPerson = {
        corporateName: data?.name,
        cnpj: data?.cnpj,
      };
      if (data.revenue) {
        client.legalPerson.revenue = unMaskedCurrency(data.revenue) / 100;
      }
      if (data.mainActivity) {
        client.legalPerson.mainActivity = data.mainActivity;
      }
    }
  };

  processSteps = async (
    data: SimulatorFormData,
    steps: SimulatorSteps[],
    isPhysicalPerson: boolean,
    setAddressError,
    validStates: State[],
    setHasMissingFields: (value: boolean) => void
  ): Promise<ClientLP> => {
    const client: ClientLP = {};

    for (const step of steps) {
      switch (step) {
        case SimulatorSteps.ADDRESS: {
          const address = await this.processAddress(data.zipCode, setAddressError, validStates, data, setHasMissingFields);
          client.address = address;
          break;
        }
        case SimulatorSteps.DATA:
          this.processData(client, data, isPhysicalPerson);
          break;
      }
    }

    return client;
  };
}

export const simulatorHandler = new SimulatorHandler();
