import ContainerEditDocument from 'components/container-edit-document';
import ContainerEditUploadedDocument from 'components/container-edit-uploaded-document';
import ReactHookFormProvider from 'components/ReactHookFormProvider';
import { Attachment } from 'model/attachment';
import { Client } from 'model/client';
import { AttachmentType } from 'model/enums/attachment-types';
import { useEditForm } from 'provider/edit-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StringUtils from 'shared/util/string-utils';
import * as yup from 'yup';
import { Container, DocumentContainer, IdentityContainer, Title } from './styles';

interface IResumeScreenProps {
  isIdentificationDocumentOnly?: boolean;
  attachmentResume?: Attachment;
  onlyView?: boolean;
  visible?: boolean;
  mobile?: boolean;
}

export const ResumeScreen: React.FC<IResumeScreenProps> = props => {
  const { t } = useTranslation();
  const { onClickNext, files, isLastForm, isLoading } = useEditForm();
  const schema = yup.object({
    attachmentResume: yup.mixed(),
  });
  const [requiredError, setRequiredError] = useState<string[]>([]);

  useEffect(() => {
    if (files.find(file => file.type === AttachmentType.PROOF_INCOME)) {
      setRequiredError([]);
    }
  }, [files]);

  return (
    <ReactHookFormProvider schema={schema} onSubmit={() => onClickNext({ attachments: files } as Client)}>
      <Container $visible={props.visible}>
        <DocumentContainer>
          <Title>{t('documents.identity.title')}</Title>
          <IdentityContainer>
            {!props.onlyView && (
              <ContainerEditDocument
                requiredError={requiredError.includes(AttachmentType.IDENTIFICATION_CARD)}
                nameInput="doc1"
                name="attachmentIdentification"
                type={AttachmentType.IDENTIFICATION_CARD}
                title={files && files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD)?.fileName}
                docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.IDENTIFICATION_CARD))}
              />
            )}
            <ContainerEditUploadedDocument editDocument={!props.onlyView} attachmentType={AttachmentType.IDENTIFICATION_CARD} />
          </IdentityContainer>
        </DocumentContainer>
        {!props.isIdentificationDocumentOnly && (
          <>
            <DocumentContainer>
              <Title>{t('documents.proofs.incomeProof.title')}</Title>
              <IdentityContainer>
                {!props.onlyView && (
                  <ContainerEditDocument
                    requiredError={requiredError.includes(AttachmentType.PROOF_INCOME)}
                    nameInput="doc2"
                    name="attachmentIncome"
                    type={AttachmentType.PROOF_INCOME}
                    title={files && files.find(att => att.type === AttachmentType.PROOF_INCOME)?.fileName}
                    docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.PROOF_INCOME))}
                  />
                )}
                <ContainerEditUploadedDocument editDocument={!props.onlyView} attachmentType={AttachmentType.PROOF_INCOME} />
              </IdentityContainer>
            </DocumentContainer>

            <DocumentContainer>
              <Title>{t('documents.proofs.residenceProof.title')}</Title>
              <IdentityContainer>
                {!props.onlyView && (
                  <ContainerEditDocument
                    requiredError={requiredError.includes(AttachmentType.PROOF_RESIDENCE)}
                    nameInput="doc3"
                    name="attachmentResidence"
                    type={AttachmentType.PROOF_RESIDENCE}
                    title={files && files.find(att => att.type === AttachmentType.PROOF_RESIDENCE)?.fileName}
                    docUrl={StringUtils.getFileOrUrl(files.find(att => att.type === AttachmentType.PROOF_RESIDENCE))}
                  />
                )}
                <ContainerEditUploadedDocument editDocument={!props.onlyView} attachmentType={AttachmentType.PROOF_RESIDENCE} />
              </IdentityContainer>
            </DocumentContainer>
          </>
        )}
      </Container>
    </ReactHookFormProvider>
  );
};
