import CustomButton from 'components/custom-button';
import { PageLoading } from 'components/page-loading';
import { CardBottomSvg } from 'images/card-bottom';
import { CardTopSvg } from 'images/card-top';
import { InitialDashboard } from 'model/dashboard';
import { Authority } from 'model/enums/authority';
import { OrganizationName } from 'model/enums/organization-name';
import { RoutePath } from 'model/enums/route-path';
import StepType from 'model/enums/step-type';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IRootState } from 'reducer';
import { DashboardService } from 'services/dashboard-service';
import { hasAuthorities } from 'shared/util/utils';
import { useTheme } from 'styled-components';
import whitePlus from '../../../../../images/white-plus.svg';
import { TitleHeader } from '../../styles';
import { CardsContainer, CustomAnalysisCard, CustomContractsCard, CustomPaymentsCard, CustomRegistersCard } from './styles';

const baseUrl = '/admin';

interface InitialDashboardProps {
  orgStepTypes: StepType[] | null;
}

export const InitialDashboardScreen = ({ orgStepTypes }: InitialDashboardProps) => {
  const account = useSelector((state: IRootState) => state.authentication.account);
  const authorities = useSelector((state: IRootState) => state.authentication.account?.authorities);

  const allowedFunctions = [
    Authority.ROLE_ADMIN,
    Authority.ROLE_INTERNAL_ADMIN,
    Authority.ROLE_MANAGER,
    Authority.ROLE_SELLER,
    Authority.ROLE_FINANCIAL,
    Authority.ROLE_ANALYST,
  ];
  const customPermissionForMulttiploOrganization = allowedFunctions.some(role => authorities?.includes(role));

  const { color } = useTheme();
  const [initialInfos, setInitialInfos] = useState<null | InitialDashboard>(null);
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (orgStepTypes != null) {
      if (orgStepTypes.includes(StepType.PROCEDURE) && !orgStepTypes.includes(StepType.CCB)) {
        getInitialDashboardInfos(StepType.PROCEDURE);
      }

      if (orgStepTypes.includes(StepType.CCB)) {
        getInitialDashboardInfos();
      }
    }
  }, [orgStepTypes]);

  const getInitialDashboardInfos = async (financingType?: StepType) => {
    const data = await DashboardService().getInitialDashboardInfos(financingType);
    setInitialInfos(data);
  };

  if (initialInfos == null) {
    return <PageLoading customStyle={{ width: '100%' }} />;
  }

  const redirect = () => {
    if (customPermissionForMulttiploOrganization) {
      history.push(RoutePath.REGISTER_NO_KEY);
    } else {
      history.push(RoutePath.ADMIN_REGISTER_NEW_CUSTOMER);
    }
  };

  return (
    <>
      <TitleHeader>
        <span className="title">{t('admin.dashboard.initial')}</span>
        {(hasAuthorities(account?.authorities, allowedFunctions) || customPermissionForMulttiploOrganization) && (
          <CustomButton iconSrc={whitePlus} onClick={redirect}>
            {t('admin.dashboard.initialButtonText')}
          </CustomButton>
        )}
      </TitleHeader>

      <CardsContainer>
        <CustomRegistersCard
          title={t('admin.dashboard.register')}
          redirectUrl={`${baseUrl}/cadastros`}
          totalQuantity={initialInfos?.client.total ?? 0}
          statusQuantity={[
            {
              quantity: initialInfos?.client.statusNew ?? 0,
              status: t('admin.dashboard.statusQuantity.new'),
            },
            {
              quantity: initialInfos?.client.approved ?? 0,
              status: t('admin.dashboard.statusQuantity.approved'),
            },
            {
              quantity: initialInfos?.client.validation ?? 0,
              status: t('admin.dashboard.statusQuantity.validation'),
            },
            {
              quantity: initialInfos?.client.adjust ?? 0,
              status: t('admin.dashboard.statusQuantity.adjust'),
            },
            {
              quantity: initialInfos?.client.updated ?? 0,
              status: t('admin.dashboard.statusQuantity.updated'),
            },
            {
              quantity: initialInfos?.client.adjusted ?? 0,
              status: t('admin.dashboard.statusQuantity.adjusted'),
            },
            {
              quantity: initialInfos?.client.preRejected ?? 0,
              status: t('admin.dashboard.statusQuantity.preRejected'),
            },
            {
              quantity: initialInfos?.client.rejected ?? 0,
              status: t('admin.dashboard.statusQuantity.rejected'),
            },
          ]}
          svgBackground={<CardTopSvg color={color.primaryColor} />}
        />
        <CustomAnalysisCard
          title={
            !orgStepTypes?.includes(StepType.CCB) && orgStepTypes?.includes(StepType.PROCEDURE)
              ? t('admin.dashboard.procedure')
              : orgStepTypes?.includes(StepType.CCB)
                ? t('admin.dashboard.creditReviews')
                : ''
          }
          redirectUrl={
            !orgStepTypes?.includes(StepType.CCB) && orgStepTypes?.includes(StepType.PROCEDURE)
              ? `${baseUrl}/procedimentos`
              : orgStepTypes?.includes(StepType.CCB)
                ? `${baseUrl}/creditos`
                : ''
          }
          totalQuantity={initialInfos?.financing.total ?? 0}
          statusQuantity={[
            {
              quantity: initialInfos?.financing.statusNew ?? 0,
              status: t('admin.dashboard.statusQuantity.new'),
            },
            {
              quantity: initialInfos?.financing.finished ?? 0,
              status: t('admin.dashboard.statusQuantity.finished'),
            },
            {
              quantity: initialInfos?.financing.approved ?? 0,
              status: t('admin.dashboard.statusQuantity.approved'),
            },
            {
              quantity: initialInfos?.financing.validation ?? 0,
              status: t('admin.dashboard.statusQuantity.validation'),
            },
            {
              quantity: initialInfos?.financing.inProgress ?? 0,
              status: t('admin.dashboard.statusQuantity.inProgress'),
            },
            {
              quantity: initialInfos?.financing.adjust ?? 0,
              status: t('admin.dashboard.statusQuantity.adjust'),
            },
            {
              quantity: initialInfos?.financing.rejected ?? 0,
              status: t('admin.dashboard.statusQuantity.rejected'),
            },
          ]}
        />
        <CustomContractsCard
          title={t('admin.dashboard.contracts')}
          redirectUrl={`${baseUrl}/contratos`}
          totalQuantity={initialInfos?.contract.total ?? 0}
          statusQuantity={[
            {
              quantity: initialInfos?.contract.statusNew ?? 0,
              status: t('admin.dashboard.statusQuantity.new'),
            },
            { quantity: initialInfos?.contract.inProgress ?? 0, status: t('admin.dashboard.statusQuantity.inProgress') },
            { quantity: initialInfos?.contract.finished ?? 0, status: t('admin.dashboard.statusQuantity.finished') },
            { quantity: initialInfos?.contract.cancelled ?? 0, status: t('admin.dashboard.statusQuantity.cancelled') },
          ]}
          svgBackground={<CardBottomSvg color={color.primaryColor} />}
        />
        <CustomPaymentsCard
          title={t('admin.dashboard.payments')}
          redirectUrl={`${baseUrl}/pagamentos`}
          totalQuantity={initialInfos?.payment.total ?? 0}
          statusQuantity={[
            { quantity: initialInfos?.payment.statusNew ?? 0, status: t('admin.dashboard.statusQuantity.new') },
            { quantity: initialInfos?.payment.inProgress ?? 0, status: t('admin.dashboard.statusQuantity.inProgress') },
            { quantity: initialInfos?.payment.finished ?? 0, status: t('admin.dashboard.statusQuantity.finished') },
            { quantity: initialInfos?.payment.cancelled ?? 0, status: t('admin.dashboard.statusQuantity.cancelled') },
          ]}
        />
      </CardsContainer>
    </>
  );
};
