import { FormAddress } from 'components/register-forms-mobile/address';
import { FormDocuments } from 'components/register-forms-mobile/documents';
import { FormIncome } from 'components/register-forms-mobile/income';
import { FormPassword } from 'components/register-forms-mobile/password';
import { FormPersonalData } from 'components/register-forms-mobile/personal-data';
import { FormGeneralData } from 'components/register-forms-mobile/general-data';
import { FormStepType } from 'model/enums/form-step-type';
import { useRegisterForm } from 'provider/register-form';
import { FormPatrimonialSituation } from 'components/register-forms-mobile/patrimonial-situation';
import { FormPartnerRelations } from 'components/register-forms-mobile/partner-relations';
import { FormLegalPersonDocuments } from 'components/register-forms-mobile/legal-person-documents';
import { useEffect } from 'react';
import { FormPersonalDataMulttiplo } from 'components/register-forms/personal-data-multtiplo';

interface IDynamicFormProps {
  type: string;
}

const DynamicMobileForm: React.FC<IDynamicFormProps> = props => {
  const { getClientData } = useRegisterForm();

  useEffect(() => {
    getClientData();
  }, []);

  const renderForms = () => {
    switch (props.type) {
      case FormStepType.PERSONAL_DATA:
        return <FormPersonalData />;
      case FormStepType.ADDRESS:
        return <FormAddress />;
      case FormStepType.INCOME:
        return <FormIncome />;
      case FormStepType.PHYSICAL_PERSON_DOCUMENTS:
        return <FormDocuments />;
      case FormStepType.GENERAL_DATA:
        return <FormGeneralData />;
      case FormStepType.PATRIMONIAL_SITUATION:
        return <FormPatrimonialSituation />;
      case FormStepType.PARTNER_RELATIONS:
        return <FormPartnerRelations />;
      case FormStepType.LEGAL_PERSON_DOCUMENTS:
        return <FormLegalPersonDocuments />;
      case FormStepType.PASSWORD:
        return <FormPassword />;
      case FormStepType.PERSONAL_DATA_MULTTIPLO:
        return <FormPersonalDataMulttiplo />;
      default:
        return <></>;
    }
  };

  return renderForms();
};

export default DynamicMobileForm;
