enum FinancingStatusType {
  NEW = 'NEW',
  ERROR = 'Error',
  ADJUSTED = 'ADJUSTED',
  ADJUST = 'ADJUST',
  IN_ANALYSIS = 'IN_ANALYSIS',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  VALIDATION = 'VALIDATION',
  CONTRACT = 'CONTRACT',
  PAYMENT = 'PAYMENT',
  CANCELLED = 'CANCELLED',
  PENDING = 'PENDING',
  DONE = 'Done',
  DONE1 = 'done',
  PENDING_PAYMENT = 'PENDING_PAYMENT',
  PENDING_SIGN = 'PENDING_SIGN',
  TRANSFER_CREDITED = 'TRANSFER_CREDITED',
  TRANSFER_REQUESTED = 'TRANSFER_REQUESTED',
  PROCESSING_QUEUE = 'Processing_queue',
  PROCESSING = 'PROCESSING',
  UNDEFINED = 'UNDEFINED',
}

export default FinancingStatusType;
