import i18n from 'config/i18n';

const useCustomStepper = (bmp = false) => {
  const stepNames = [
    i18n.t('admin.newBalance.steps.generatingPixPayment'),
    i18n.t('admin.newBalance.steps.pixPayment'),
    i18n.t('admin.newBalance.steps.transferNellPayIugu'),
    i18n.t('admin.newBalance.steps.transferConfirmation'),
    i18n.t('admin.newBalance.steps.transferNellPayGiro'),
    i18n.t('admin.newBalance.steps.transferConfirmation'),
  ];

  const BMPstepNames = [
    i18n.t('admin.newBalance.steps.generatingPixPayment'),
    i18n.t('admin.newBalance.steps.pixPayment'),
    i18n.t('admin.newBalance.steps.transferBMP'),
    i18n.t('admin.newBalance.steps.transferConfirmation'),
  ];

  if (bmp) {
    return { stepNames: BMPstepNames };
  } else {
    return { stepNames };
  }
};
export default useCustomStepper;
