import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { SERVER_API_URL } from 'config/constants';
import { OrganizationName } from 'model/enums/organization-name';
import AuthUtils from 'shared/util/auth-utils';

const createApiInstance = (): AxiosInstance => {
  const config: AxiosRequestConfig = {
    baseURL: SERVER_API_URL,
    timeout: 60 * 1000,
  };

  const instance = axios.create(config);
  // tslint:disable-next-line: no-shadowed-variable
  instance.interceptors.request.use(async config => {
    const token = AuthUtils.getToken();

    if (config.headers) {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      if (process.env.NODE_ENV === 'development') {
        config.headers['X-OrganizationID'] = OrganizationName.MULTTIPLO;
      }
      config.headers['x-url-origin'] = window.location.href;
    }

    return config;
  });

  instance.interceptors.response.use(
    response => {
      const newToken = response.headers['X-New-Token'];
      if (newToken != null && newToken !== '') {
        AuthUtils.setToken(newToken);
      }
      return response;
    },
    error => {
      if (error.response.status == 403) {
        window.location.replace('/sem-acesso');
      }
      return Promise.reject(error);
    }
  );

  return instance;
};

export const api = createApiInstance();
export default {
  api,
};
