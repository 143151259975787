import SimulatorContext from 'context/simulator';
import useSimulatorStorage from 'hooks/use-simulator-storage';
import { CreateFinancingResponse } from 'model/create-financing-response';
import { FinancingSimpleSimulation } from 'model/financing';
import { useContext } from 'react';
import landingPageService from 'services/landing-page-service';

export const useSendDataToSimulator = () => {
  const { setSimulatorScreenData, setSimulatorInsuranceInfo } = useContext(SimulatorContext);
  const { setClientSimulatorInfoToStorage } = useSimulatorStorage();

  const sendDataToSimulator = async (
    simulationData: FinancingSimpleSimulation,
    isNewCredit: boolean,
    isAdmin: boolean,
    withInsurance: boolean = false
  ): Promise<CreateFinancingResponse> => {
    try {
      const simulationSender = getSimulationInfoSenderForOrganization(isNewCredit);
      const simulatorSavedData = await simulationSender({ ...simulationData, isInsured: withInsurance });
      simulationData.id = simulatorSavedData.financingId;

      const simulatorClientId = isAdmin ? `${simulationData.client?.id ?? ''}` : undefined;

      saveInfoFromSimulation(simulationData, simulatorSavedData, simulatorClientId, withInsurance);

      return simulatorSavedData;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const getSimulationInfoSenderForOrganization = (isNewCredit: boolean) => {
    return isNewCredit ? landingPageService.postSimulationDraft : landingPageService.postSimulator;
  };

  const saveInfoFromSimulation = async (
    simulationData: FinancingSimpleSimulation,
    simulationSavedData: CreateFinancingResponse,
    clientId?: string,
    withInsurance: boolean = false
  ) => {
    if (withInsurance) {
      setSimulatorInsuranceInfo(simulationSavedData);
    } else {
      setClientSimulatorInfoToStorage(clientId, simulationData);
      setSimulatorScreenData(simulationSavedData);
    }
  };

  return {
    sendDataToSimulator,
  };
};
