import styled from 'styled-components';

import theme from '../../../../../themes/default';

interface Props {
  $color: string;
}

const statusColor = {
  NEW: theme.color.statusNew,
  PROCESSING: theme.color.in_progress,
  TRANSFER_REQUESTED: theme.color.in_progress,
  PROCESSING_QUEUE: theme.color.in_progress,
  IN_PROGRESS: theme.color.in_progress,
  VALIDATION: theme.color.in_progress,
  ADJUST: theme.color.in_progress,
  ADJUSTED: theme.color.statusNew,
  APPROVED: theme.color.approved,
  FINISHED: theme.color.finished,
  REJECTED: theme.color.rejected,
  CANCELLED: theme.color.cancelled,
  CANCELED: theme.color.cancelled,
  PRE_REJECTED: theme.color.rejected,
  UPDATED: theme.color.in_progress,
  ACTIVE: theme.color.green,
  INACTIVE: theme.color.rejected,
  BLOCKED: theme.color.rejected,
  Sent: theme.color.in_progress,
  Replied: theme.color.green,
  Expired: theme.color.rejected,
  Waiting: theme.color.in_progress,
  WAITING_TO_APPLY_AMOUNT: theme.color.in_progress,
  Done: theme.color.green,
  done: theme.color.green,
  processing: theme.color.in_progress,
  Processing_queue: theme.color.in_progress,
  Error: theme.color.rejected,
  OVERDUE: theme.color.rejected,
  PENDING: theme.color.in_progress,
  PAID: theme.color.green,
  ORGANIZATION: theme.color.primaryColor,
  CLIENT: theme.color.primaryColor,
  SIMULATION: theme.color.statusNew,
  AUTHORIZED: theme.color.in_progress,
  CAPTURED: theme.color.in_progress,
  REPROVED: theme.color.rejected,
  FINALIZED: theme.color.finished,
  AWAITING_SUBMISSION_FOR_SIGNATURE: theme.color.statusNew,
  SENT_FOR_SIGNATURE: theme.color.in_progress,
  ERROR_ANTI_FRAUD_OCR: theme.color.rejected,
  ERROR_ANTI_FRAUD_FACE_MATCH: theme.color.rejected,
  ERROR_ANTI_FRAUD_LIVENESS: theme.color.rejected,
  AWAITING_SIGNED_CONTRACT: theme.color.green,
  DONE: theme.color.green,
  PENDING_PAYMENT: theme.color.in_progress,
  PENDING_SIGN: theme.color.in_progress,
  DEALER: theme.color.primaryColor,
};

export const Container = styled.div<Props>`
  justify-content: center;
  width: fit-content;

  min-height: 20px;
  background-color: ${({ $color }) => statusColor[$color]};
  padding: 0px 8px;
  border-radius: 4px;

  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 14px;
  color: ${theme.color.white};
`;
