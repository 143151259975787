export enum FormStepType {
  PERSONAL_DATA = 'PERSONAL_DATA',
  PERSONAL_DATA_MULTTIPLO = 'PERSONAL_DATA_MULTTIPLO',
  ADDRESS = 'ADDRESS',
  ADDRESS_MULTTIPLO = 'ADDRESS_MULTTIPLO',
  INCOME = 'INCOME',
  SELFIE = 'SELFIE',
  PHYSICAL_PERSON_DOCUMENTS = 'PHYSICAL_PERSON_DOCUMENTS',
  PHYSICAL_PERSON_IDENTIFICATION_DOCUMENT = 'PHYSICAL_PERSON_IDENTIFICATION_DOCUMENT',
  PASSWORD = 'PASSWORD',
  FRAUD_ANALYSIS_SELFIE = 'FRAUD_ANALYSIS_SELFIE',
  SCORE_ANALYSIS = 'SCORE_ANALYSIS',
  DOC_FRAUD_ANALYSIS = 'DOC_FRAUD_ANALYSIS',
  BANCK_ACCOUNT_DATA = 'BANCK_ACCOUNT_DATA',
  GENERAL_DATA = 'GENERAL_DATA',
  PARTNER_RELATIONS = 'PARTNER_RELATIONS',
  PATRIMONIAL_SITUATION = 'PATRIMONIAL_SITUATION',
  LEGAL_PERSON_DOCUMENTS = 'LEGAL_PERSON_DOCUMENTS',
}
