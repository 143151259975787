import AdminFiltersContext from 'context/admin-filters';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { FilterButton } from '../filter-button';
import SearchInput from '../search-input';
import { ButtonContainer, Container, Title } from './styles';
import { CreatedDates } from 'model/date';
import { useAdmin } from 'provider/admin-general';

interface TitleHeaderProps {
  title: string;
  setSearch?: (value: string) => void;
  setSearchOption?: (value: string) => void;
  searchOption?: string;
  setSubsidiarySearch?: (value: string) => void;
  setFilters?: () => void;
  cleanFilters?: () => void;
  setOrderBy?: (value: boolean) => void;
  filterStatusValues?: string[];
  customButton?: JSX.Element;
  orderBy?: boolean;
}

export const NewTitleHeader = ({
  title,
  setSearch,
  setSearchOption,
  searchOption,
  setSubsidiarySearch,
  setFilters,
  filterStatusValues,
  customButton,
  cleanFilters,
  orderBy,
  setOrderBy,
}: TitleHeaderProps) => {
  const { t } = useTranslation();
  const [gte, setGte] = useState<Date | null>(null);
  const [lte, setLte] = useState<Date | null>(null);
  const [calendarDates, setCalendarDates] = useState<CreatedDates | null>(null);

  const { filters, updateStatusFilters, updateDateRange } = useContext(AdminFiltersContext);
  const { handleSetCreatedDates, resetCreatedDates, setSaveCalendarDates, saveCalendarDates } = useAdmin();

  const [dateSelect, setDateSelect] = useState<number | null>(null);
  const account = useSelector((state: IRootState) => state.authentication.account);

  const handleFilterStatus = (status: string) => {
    let newFilters: string[] = [];

    if (status !== '') {
      newFilters = [...filters.statusFilters.filter(filter => filter !== '')];
      if (newFilters.includes(status)) {
        newFilters = newFilters.filter(filter => filter !== status);
      } else {
        newFilters.push(status);
      }
    }

    if (newFilters.length === 0) {
      newFilters = [''];
    }
    updateStatusFilters(newFilters);
  };

  const handleFilterDate = (date: globalThis.Date, type) => {
    setDateSelect(null);
    updateDateRange({ [type]: date.toISOString().split('T')[0] });
    setCalendarDates({ [type]: date.toISOString().split('T')[0] });
  };

  useEffect(() => {
    if (calendarDates?.gte || calendarDates?.lte) {
      handleSetCreatedDates(calendarDates);
      setSaveCalendarDates(calendarDates);
    }
  }, [calendarDates]);

  const activeGte = filters.dateRange.gte !== '';
  const activeLte = filters.dateRange.lte !== '';

  const renderContent = () => {
    return (
      <>
        <Title>{title}</Title>
        <ButtonContainer>
          <SearchInput setSearch={setSearch} />
          {filterStatusValues && (
            <FilterButton
              filterStatus={filterStatusValues}
              onAppliedFilters={() => {
                if (setFilters) {
                  setFilters();
                }
              }}
              onCleanFilter={() => {
                if (cleanFilters) {
                  cleanFilters();
                }
              }}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
            />
          )}
          {customButton && !filterStatusValues && customButton}
        </ButtonContainer>
      </>
    );
  };
  return <Container $changeLayout={false}>{renderContent()}</Container>;
};
