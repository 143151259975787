import { FormAddress } from 'components/register-forms/address';
import { FormAddressMulttiplo } from 'components/register-forms/address-multtiplo';
import { FormDocuments } from 'components/register-forms/documents';
import { FormGeneralData } from 'components/register-forms/general-data';
import { FormIncome } from 'components/register-forms/income';
import { FormLegalPersonDocuments } from 'components/register-forms/legal-person-documents';
import { FormPartnerRelations } from 'components/register-forms/partner-relations';
import { FormPassword } from 'components/register-forms/password';
import { FormPatrimonialSituation } from 'components/register-forms/patrimonial-situation';
import { FormPersonalData } from 'components/register-forms/personal-data';
import { FormPersonalDataMulttiplo } from 'components/register-forms/personal-data-multtiplo';
import { FormStepType } from 'model/enums/form-step-type';
import { useRegisterForm } from 'provider/register-form';
import { useEffect } from 'react';

interface IDynamicFormProps {
  type: string;
}

const DynamicDesktopForm: React.FC<IDynamicFormProps> = props => {
  const { getClientData } = useRegisterForm();

  useEffect(() => {
    getClientData();
  }, []);

  const renderForms = () => {
    switch (props.type) {
      case FormStepType.PERSONAL_DATA:
        return <FormPersonalData />;
      case FormStepType.PERSONAL_DATA_MULTTIPLO:
        return <FormPersonalDataMulttiplo />;
      case FormStepType.GENERAL_DATA:
        return <FormGeneralData />;
      case FormStepType.ADDRESS:
        return <FormAddress />;
      case FormStepType.PARTNER_RELATIONS:
        return <FormPartnerRelations />;
      case FormStepType.PATRIMONIAL_SITUATION:
        return <FormPatrimonialSituation />;
      case FormStepType.ADDRESS_MULTTIPLO:
        return <FormAddressMulttiplo />;
      case FormStepType.INCOME:
        return <FormIncome />;
      case FormStepType.PHYSICAL_PERSON_DOCUMENTS:
        return <FormDocuments />;
      case FormStepType.LEGAL_PERSON_DOCUMENTS:
        return <FormLegalPersonDocuments />;
      case FormStepType.PHYSICAL_PERSON_IDENTIFICATION_DOCUMENT:
        return <FormDocuments isOnlyIdentificationDocument />;
      case FormStepType.PASSWORD:
        return <FormPassword />;
      default:
        return <></>;
    }
  };
  return renderForms();
};

export default DynamicDesktopForm;
