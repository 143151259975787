import AdminFiltersContext from 'context/admin-filters';
import { Authority } from 'model/enums/authority';
import { OrganizationName } from 'model/enums/organization-name';
import moment from 'moment';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { hasAuthorities } from 'shared/util/utils';
import { FilterButton } from '../filter-button';
import SearchInput from '../search-input';
import { ButtonContainer, Container, FilterContent, FilterItem, StyledTitleContainer, Title } from './styles';

interface TitleHeaderProps {
  title: string;
  setSearch?: (value: string) => void;
  setSubsidiarySearch?: (value: string) => void;
  setFilters?: () => void;
  cleanFilters?: () => void;
  setOrderBy?: (value: boolean) => void;
  filterValues?: string[];
  customButton?: JSX.Element;
  orderBy?: boolean;
}

export const TitleHeader = ({
  title,
  setSearch,
  setSubsidiarySearch,
  setFilters,
  filterValues,
  customButton,
  cleanFilters,
  orderBy,
  setOrderBy,
}: TitleHeaderProps) => {
  const { t } = useTranslation();
  const [gte, setGte] = useState<Date | null>(null);
  const [lte, setLte] = useState<Date | null>(null);

  const { filters, updateStatusFilters, updateDateRange } = useContext(AdminFiltersContext);

  const [dateSelect, setDateSelect] = useState<number | null>(null);
  const account = useSelector((state: IRootState) => state.authentication.account);

  const handleFilterStatus = (status: string) => {
    let newFilters: string[] = [];

    if (status !== '') {
      newFilters = [...filters.statusFilters.filter(filter => filter !== '')];
      if (newFilters.includes(status)) {
        newFilters = newFilters.filter(filter => filter !== status);
      } else {
        newFilters.push(status);
      }
    }

    if (newFilters.length === 0) {
      newFilters = [''];
    }
    updateStatusFilters(newFilters);
  };

  const handleFilterDate = (date: globalThis.Date, type) => {
    setDateSelect(null);
    updateDateRange({ [type]: date.toISOString().split('T')[0] });
  };

  const activeGte = filters.dateRange.gte !== '';
  const activeLte = filters.dateRange.lte !== '';

  const areAllFiltersUnselected = () => {
    return filters.statusFilters.every(filter => filter === '');
  };

  const renderSearchButton = () => {
    if (setSubsidiarySearch) {
      if (hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN])) {
        return <SearchInput title={t('admin.dashboard.searchSubsidiaries')} setSearch={setSubsidiarySearch} />;
      }
    }
  };

  const renderContent = () => {
    return (
      <>
        <StyledTitleContainer>
          <Title>{title}</Title>
          {customButton}
        </StyledTitleContainer>
        <ButtonContainer>
          {renderSearchButton()}
          <SearchInput setSearch={setSearch} />
          {filterValues && (
            <FilterButton
              filterStatus={filterValues}
              onAppliedFilters={() => {
                if (setFilters) {
                  setFilters();
                }
              }}
              onCleanFilter={() => {
                if (cleanFilters) {
                  cleanFilters();
                }
              }}
              orderBy={orderBy}
              setOrderBy={setOrderBy}
            />
          )}
        </ButtonContainer>
      </>
    );
  };
  return <Container $changeLayout>{renderContent()}</Container>;
};
