import { Tab } from 'features/admin/dashboard';
import { useAccountInfo } from 'hooks/use-account-info';
import { CreatedDates } from 'model/date';
import { Authority } from 'model/enums/authority';
import { Ordertype } from 'model/enums/order-type';
import StepType from 'model/enums/step-type';
import { Financing } from 'model/financing';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import organizationSystemStepsService from 'services/organization-system-steps-service';
import { setStorageFilters } from 'shared/util/filter-utils';
import { hasAuthorities } from 'shared/util/utils';
import { CUSTOM_TABS, DEFAULT_TABS } from './constants';

interface AdminData {
  statusFilters: string[];
  createdDates: CreatedDates;
  saveCalendarDates: CreatedDates | null;
  reloadProcedure: boolean;
  stepFinance: Financing | null;
  handleSetNewStatus: (status: string[]) => void;
  handleSetCreatedDates: (dates: CreatedDates) => void;
  setSaveCalendarDates: (dates: CreatedDates | null) => void;
  handleProcedureReload: (reload: boolean) => void;
  resetStatusFilters: () => void;
  resetCreatedDates: () => void;
  handleSetStepFinance: (finance: Financing) => void;
  clearStepFinance: () => void;
  sort: string;
  sortAscending: () => void;
  sortDescending: () => void;
  tabsMenu: Tab[];
  isAdmin: boolean;
}

interface AdminProps {
  children: ReactNode;
}

const AdminContext = createContext<AdminData>({} as AdminData);

export const AdminProvider = ({ children }: AdminProps) => {
  const [statusFilters, setStatusFilters] = useState<string[]>([]);
  const [createdDates, setCreatedDates] = useState<CreatedDates>({ lte: '', gte: '' });
  const [sort, setSort] = useState<Ordertype>(Ordertype.DESC);
  const [saveCalendarDates, setSaveCalendarDates] = useState<CreatedDates | null>(null);
  const [reloadProcedure, setReloadProcedure] = useState(false);
  const [stepFinance, setStepFinance] = useState<Financing | null>(null);
  const account = useSelector((state: IRootState) => state.authentication.account);

  const [tabsMenu, setTabsMenu] = useState<Tab[]>([]);

  const { internalHasSubsidiary } = useAccountInfo();

  const isAdminOrInternalAdmin = hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN]);
  const isAdmin = hasAuthorities(account?.authorities, [Authority.ROLE_ADMIN]);

  const getStepType = async () => {
    const res = await organizationSystemStepsService.getStepAdmin();
    const orgTypes = res
      .filter(
        orgType =>
          orgType.stepType === StepType.CREDIT_ANTICIPATION || orgType.stepType === StepType.CCB || orgType.stepType === StepType.PROCEDURE
      )
      .map(type => type.stepType);

    const filtered = orgTypes.filter((orgType, idx) => orgTypes.indexOf(orgType) === idx);

    if (filtered.length > 0) {
      getTabs(filtered as StepType[]);
    } else {
      getTabs([]);
    }
  };

  const getTabs = (orgStepTypes: StepType[]) => {
    let tabs = [...DEFAULT_TABS];

    if (!tabs.includes(CUSTOM_TABS.paymentTab)) {
      tabs.push(CUSTOM_TABS.paymentTab);
    }

    if (isAdminOrInternalAdmin && !account?.internal?.organizationSubsidiary) {
      if (!tabs.includes(CUSTOM_TABS.myTeamTab)) {
        tabs.splice(tabs.length, 0, CUSTOM_TABS.myTeamTab);
      }
    }

    if (orgStepTypes != null) {
      if (orgStepTypes.includes(StepType.CREDIT_ANTICIPATION) && !tabs.includes(CUSTOM_TABS.anticipationTab)) {
        tabs.splice(tabs.length - 1, 0, CUSTOM_TABS.anticipationTab);
      }

      if (orgStepTypes.includes(StepType.PROCEDURE) && !tabs.includes(CUSTOM_TABS.procedureTab)) {
        tabs.splice(2, 0, CUSTOM_TABS.procedureTab);
      }

      if (orgStepTypes.includes(StepType.CCB) && !tabs.includes(CUSTOM_TABS.creditTab)) {
        tabs.splice(2, 0, CUSTOM_TABS.creditTab);
      }
    }

    if (!tabs.includes(CUSTOM_TABS.branchTab) && isAdminOrInternalAdmin && !account?.internal?.organizationSubsidiary) {
      tabs.push(CUSTOM_TABS.branchTab);
    }

    if (!tabs.includes(CUSTOM_TABS.myAccountTab)) {
      tabs.push(CUSTOM_TABS.myAccountTab);
    }

    if (!tabs.includes(CUSTOM_TABS.reportTab) && isAdminOrInternalAdmin) {
      tabs.push(CUSTOM_TABS.reportTab);
    }

    if (isAdminOrInternalAdmin && !internalHasSubsidiary) {
      tabs.push(CUSTOM_TABS.partnersTab);
    }

    setTabsMenu(tabs);
  };

  useEffect(() => {
    getStepType();
  }, []);

  const handleSetNewStatus = (status: string[]) => {
    setStatusFilters(status);
    setStorageFilters({ statusFilters: status });
  };

  const resetStatusFilters = () => {
    setStatusFilters([]);
    setStorageFilters({ statusFilters: [] });
  };

  const handleSetCreatedDates = (dates: CreatedDates) => {
    setCreatedDates(dates);
  };

  const resetCreatedDates = () => {
    setCreatedDates({ lte: '', gte: '' });
  };

  const sortAscending = () => {
    setSort(Ordertype.ASC);
  };

  const sortDescending = () => {
    setSort(Ordertype.DESC);
  };

  const handleProcedureReload = (reload: boolean) => {
    setReloadProcedure(reload);
  };

  const handleSetStepFinance = (finance: Financing) => {
    setStepFinance(finance);
  };

  const clearStepFinance = () => {
    setStepFinance(null);
  };

  return (
    <AdminContext.Provider
      value={{
        statusFilters,
        handleSetNewStatus,
        resetStatusFilters,
        createdDates,
        handleSetCreatedDates,
        resetCreatedDates,
        saveCalendarDates,
        setSaveCalendarDates,
        handleProcedureReload,
        reloadProcedure,
        handleSetStepFinance,
        stepFinance,
        clearStepFinance,
        tabsMenu,
        sort,
        sortAscending,
        sortDescending,
        isAdmin,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export const useAdmin = () => useContext(AdminContext);
