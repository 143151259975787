import registerApi from 'api/register-api';
import EnterCustomButton from 'components/enter-custom-button';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ThinBackground, ThinButtonContainer, ThinContainer, ThinSubTitle, ThinTitle } from 'styled-components/thin-styles';

const ConfirmRegistration: React.FC = props => {
  const { t } = useTranslation();
  const history = useHistory();
  const useQuery = () => {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
  };

  const query = useQuery();

  useEffect(() => {
    const key = query.get('key');

    if (key != null) {
      registerApi.confirmEmail(key).then(res => {
        return;
      });
    }
  });

  return (
    <ThinBackground>
      <ThinContainer>
        <ThinTitle>{t('login.confirmRegistration.title')}</ThinTitle>
        {<ThinSubTitle>{t('login.confirmRegistration.subtitle')}</ThinSubTitle>}
      </ThinContainer>

      <ThinButtonContainer>
        <EnterCustomButton
          onClick={() => history.push('/login')}
          width={300}
          text={t('global.button.continue')}
          disabled={false}
          isLoading={false}
        />
      </ThinButtonContainer>
    </ThinBackground>
  );
};

export default ConfirmRegistration;
