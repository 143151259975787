export enum BankAccountType {
  CHECKING_ACCOUNT = 'Conta corrente',
  SAVING_ACCOUNT = 'Conta poupança',
  SALARY_ACCOUNT = 'Conta salário',
}
export enum ProcedureBankAccountType {
  PERSONAL_ACCOUNT = 'PERSONAL_ACCOUNT',
  IUGU = 'IUGU',
  BMP = 'BMP',
}
