export enum IuguTranferStatus {
  PENDING = 'PENDING',
  TRANSFER_CREDITED = 'TRANSFER_CREDITED',
  TRANSFER_REQUESTED = 'TRANSFER_REQUESTED',
  PROCESSING_QUEUE = 'PROCESSING_QUEUE',
  PROCESSING = 'PROCESSING',
  REJECTED = 'REJECTED',
  DONE = 'DONE',
  ERROR = 'ERROR',
  UNDEFINED = 'UNDEFINED',
}
