export enum validationEnum {
  formType = 'formType',
  fieldType = 'fieldType',
}

export enum FlowMap {
  DEFAULT = 'DEFAULT',
}

export enum OrganizationTypes {
  multtiplo = 'multtiplo',
}
