import { Status } from 'features/admin/dashboard/components/status';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdminDropdown from '../dropdown';
import { ButtonPayment } from '../service-payment/styled';
import {
  DeletePayment,
  DynamicForm,
  PaymentClosedHeader,
  PaymentContainer,
  PaymentHeader,
  PaymentTitle,
  PaymentValue,
  PyamentClosedButtonBox,
  SeeDetails,
  Separate,
} from './styles';

import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Snackbar } from '@mui/material';
import { AttachmentType } from 'model/enums/attachment-types';
import { ProcedureBankAccountType } from 'model/enums/bank-account-type';
import FinancingStatusType from 'model/enums/financing-status-type';
import { Financing, Payment } from 'model/financing';
import { PaymentFormProperties, PaymentMethod, PaymentSectionType } from 'model/procedurePayment';
import { FormProvider, useForm } from 'react-hook-form';
import { ImAttachment } from 'react-icons/im';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { PopupActions } from 'reactjs-popup/dist/types';
import financingService from 'services/financing-service';
import { formatDateToLocalFormat } from 'shared/util/date-utils';
import StringUtils, { validateCNPJ, validateCpf, validatePhone } from 'shared/util/string-utils';
import * as yup from 'yup';
import { paymentOptions, pixOptions } from '../..';
import UploadComponent from '../admin-attach';
import { StyledInputBox, StyledLabel, UploadedBox, UploadedFileLink, UploadedFileName, UploadedImage } from '../admin-attach/style';
import ConfirmAction from '../confirm-action';
import AdminInput, { AdminInputType } from '../input';
import { PixType } from 'model/enums/payment-type';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { Authority } from 'model/enums/authority';
import { IPix } from 'model/bank-account';

interface PaymentSectionProps {
  sectionType: string;
  title: string;
  position: number;
  onDelete: () => void;
  procedureBankAccountType?: string;
  payment: Payment;
  setPaymentListData: (value) => void;
  getPaymentData: () => void;
  maxPaymentValue?: number;
  buttonPayment: string;
  errorPaymentMessage?: string;
  procedure?: Financing;
}

const PaymentSection: React.FC<PaymentSectionProps> = ({
  title,
  payment,
  setPaymentListData,
  position,
  onDelete,
  procedureBankAccountType,
  sectionType,
  getPaymentData,
  maxPaymentValue,
  buttonPayment,
  errorPaymentMessage,
  procedure,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [openAlert, setOpenAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { t } = useTranslation();
  const account = useSelector((state: IRootState) => state.authentication.account);
  const [pixKeyOptions, setPixKeyOptions] = useState<IPix[]>([]);
  const [pixKeyDefaultValue, setPixKeyDefaultValue] = useState<string>();
  const [paymentTypeDefaultValue, setPaymentTypeDefaultValue] = useState<string>();

  console.log('payment', payment);

  useEffect(() => {
    if (procedure?.organizationSubsidiary?.pixRegistrations && payment.pixTypeObject?.value) {
      let filteredValues = procedure?.organizationSubsidiary?.pixRegistrations.filter(
        item => item.pixType === payment.pixTypeObject?.value
      );
      setPixKeyOptions(filteredValues);
      if (filteredValues.length === 1) {
        setPixKeyDefaultValue(filteredValues[0].pixKey);
      } else if (filteredValues.length === 0) {
        setPixKeyOptions(procedure?.organizationSubsidiary?.pixRegistrations);
      }
    } else if (procedure?.organizationSubsidiary?.pixRegistrations) {
      setPixKeyOptions(procedure?.organizationSubsidiary?.pixRegistrations);
    }
  }, [payment.pixTypeObject?.value]);

  useEffect(() => {
    if (payment.status === FinancingStatusType.ERROR) {
      setPaymentTypeDefaultValue(PaymentMethod.PIX);
    }
    if (payment.procedurePaymentType) {
      setPaymentTypeDefaultValue(payment.procedurePaymentType);
    }
  }, []);

  const [isLoading, setIsLoading] = useState<number>(0);

  const modalRef = useRef<PopupActions>(null!);
  const closeTooltip = () => {
    if (modalRef?.current) {
      modalRef.current.close();
    }
  };

  const schema = yup.object().shape({
    [PaymentFormProperties.VALUE]: yup
      .string()
      .required(t('landingPage.openingScreen.simulatorForm.formValidations.required'))
      .test('test-value', t('landingPage.openingScreen.simulatorForm.formValidations.required'), val => {
        if (Number(StringUtils.removeNonNumbersFromMaskedValue(payment.value ?? '')) / 100 === 0) {
          return false;
        }
        return true;
      }),
    ...(sectionType === PaymentSectionType.PAYMENT
      ? {
          [PaymentFormProperties.PROCEDURE_PAYMENT_TYPE]: yup
            .string()
            .transform((val, originalValue) => originalValue)
            .test('test-paymentType', val => (val && val !== '' ? true : false)),
        }
      : {}),
    ...(sectionType === PaymentSectionType.REIMBURSEMENT
      ? {
          [PaymentFormProperties.ATTACHMENTS]: yup
            .mixed()
            .test('test-fileRequired', t('global.errorMessage.requiredUpload'), value => (!value ? false : true))
            .test('test-fileSize', t('global.errorMessage.tooBigFile'), (value: any) => {
              const maxSize = 5 * 1024 * 1024;
              return value?.file.length <= maxSize;
            }),
        }
      : {}),
    ...(payment?.[PaymentFormProperties.PROCEDURE_PAYMENT_TYPE] === PaymentMethod.PIX
      ? {
          [PaymentFormProperties.PIX_KEY]: yup
            .string()
            .required(t('landingPage.openingScreen.simulatorForm.formValidations.required'))
            .test('test-pixKey-compatibility-pixType', t('global.errorMessage.pixTypeIncompatible'), val => {
              if (payment.pixTypeObject?.value) {
                return ValidatePixFormats[payment.pixTypeObject?.value](val);
              }
              return true;
            }),

          [PaymentFormProperties.PIX_TYPE]: yup
            .string()
            .transform((val, originalValue) => originalValue)
            .test('test-pixType', val => (val && val !== '' ? true : false)),
        }
      : {}),
  });
  const methods = useForm({ resolver: yupResolver(schema), mode: 'onSubmit' });

  const handleForm = async e => {
    setIsLoading(1);
    switch (sectionType) {
      case PaymentSectionType.REIMBURSEMENT: {
        const reimbursementData: Payment = {
          procedureRefundPayments: {
            value: (Number(StringUtils.removeNonNumbersFromMaskedValue(payment.value ?? '')) / 100).toString(),
            attachments: [
              {
                ...payment.attachments,
                contentType: payment.attachments?.contentType ?? '',
                type: AttachmentType.PROCEDURE_REFUND,
              },
            ],
          },
        };

        financingService
          .refoundPayment(reimbursementData, payment?.financingId)
          .then(res => {
            setTimeout(() => {
              setIsLoading(2);
            }, 2500);
            getPaymentData();
          })
          .catch(err => {
            setErrorMessage(t(`global.errorMessage.${err.data.code}`) ?? t('global.registerError'));
            setOpenAlert(true);
            setTimeout(() => {
              setOpenAlert(false);
            }, 2000);
          })
          .finally(() => {
            closeTooltip();
            setIsLoading(0);
            setIsOpen(false);
          });

        break;
      }
      default: {
        const paymentData: Payment = {
          ...payment,
          value: (Number(StringUtils.removeNonNumbersFromMaskedValue(payment.value ?? '')) / 100).toString(),
          ...(payment?.pixKey &&
            payment?.pixTypeObject?.value === PixType.PHONE && {
              pixKey: StringUtils.removeNonNumbersFromMaskedValue(payment.pixKey),
            }),
          ...(payment?.pixTypeObject?.value &&
            (payment?.pixTypeObject?.value == 'RANDOM_KEY' ? { pixType: 'EVP' } : { pixType: payment.pixTypeObject?.value })),
          ...(payment?.procedurePaymentTypeObject?.value &&
            (payment?.procedurePaymentTypeObject?.value == 'REGISTERED_PIX'
              ? { procedurePaymentType: 'PIX' }
              : { procedurePaymentType: payment.procedurePaymentTypeObject?.value })),
        };

        delete paymentData.status;
        delete paymentData?.pixTypeObject;
        delete payment?.procedurePaymentTypeObject;
        financingService
          .makePayment(paymentData)
          .then(res => {
            setTimeout(() => {
              setIsLoading(2);
            }, 2500);
            getPaymentData();
          })
          .catch(err => {
            setErrorMessage(t(`global.errorMessage.${err.data.code}`) ?? t('global.registerError'));
            setOpenAlert(true);
            setTimeout(() => {
              setOpenAlert(false);
            }, 2000);
          })
          .finally(() => {
            closeTooltip();
            setIsLoading(0);
            setIsOpen(false);
          });
        break;
      }
    }
  };

  const verifyPaymentMethod = () => {
    switch (procedureBankAccountType) {
      case ProcedureBankAccountType.BMP:
      case ProcedureBankAccountType.IUGU: {
        const findIugu: string[] = [];
        if (
          account?.authorities?.includes(Authority.ROLE_ADMIN) &&
          procedure?.organizationSubsidiary?.onlyRegisteredPixAllowed &&
          payment.status !== FinancingStatusType.DONE1 &&
          payment.status !== FinancingStatusType.ERROR &&
          payment.status !== FinancingStatusType.PROCESSING_QUEUE
        ) {
          findIugu.push(paymentOptions.find(option => option.value === PaymentMethod.PIX)?.label ?? '');
          findIugu.push(paymentOptions.find(option => option.value === PaymentMethod.REGISTERED_PIX)?.label ?? '');
        } else if (procedure?.organizationSubsidiary?.onlyRegisteredPixAllowed) {
          findIugu.push(paymentOptions.find(option => option.value === PaymentMethod.REGISTERED_PIX)?.label ?? '');
        } else {
          findIugu.push(paymentOptions.find(option => option.value === PaymentMethod.PIX)?.label ?? '');
        }

        return findIugu;
      }
      default: {
        const findManual = [paymentOptions.find(option => option.value === PaymentMethod.MANUAL_PAYMENT)?.label ?? ''];

        return findManual;
      }
    }
  };

  const isDone = payment.status !== FinancingStatusType.PENDING;

  useEffect(() => {
    if (isDone) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    methods.trigger(PaymentFormProperties.PIX_KEY);
  }, [payment.pixType, payment.pixKey]);

  const ValidatePixFormats = {
    [PixType.CNPJ]: validateCNPJ,
    [PixType.CPF]: validateCpf,
    [PixType.EMAIL]: StringUtils.isEmailValid,
    [PixType.PHONE]: validatePhone,
    [PixType.RANDOM_KEY]: () => true,
  };

  const MaskPixFormats = {
    [PixType.CNPJ]: StringUtils.cnpjMask,
    [PixType.CPF]: StringUtils.cpfMask,
    [PixType.EMAIL]: string => string,
    [PixType.PHONE]: StringUtils.phoneMask,
    [PixType.RANDOM_KEY]: string => string,
  };

  const calcWidth = '88px';

  const renderDropDown = () => {
    return (
      <AdminDropdown
        name={PaymentFormProperties.PIX_KEY}
        required={true}
        htmlFor={PaymentFormProperties.PIX_KEY}
        label={t('enums.campNames.pixKey')}
        formData={payment}
        setFormData={setPaymentListData}
        position={position}
        isList={true}
        defaultValue={pixKeyDefaultValue}
        list={pixKeyOptions.map(item => {
          return item.pixKey ?? '';
        })}
        mask={val => {
          if (payment.pixTypeObject?.value) {
            return MaskPixFormats[payment.pixTypeObject?.value](val);
          } else {
            return val;
          }
        }}
        objectList={pixKeyOptions.map(item => {
          return { label: item.pixKey, value: item.pixKey };
        })}
        calcWidth={calcWidth}
        type={payment.status !== FinancingStatusType.PENDING ? AdminInputType.VIEW : AdminInputType.TEXT}
      />
    );
  };

  const renderInput = () => {
    switch (payment?.procedurePaymentTypeObject?.value) {
      case PaymentMethod.BANK_ACCOUNT:
        return (
          <>
            <AdminInput
              name={PaymentFormProperties.BENEFICIARY_NAME}
              required={true}
              htmlFor={PaymentFormProperties.BENEFICIARY_NAME}
              label={t('admin.procedureScreen.payments.beneficName')}
              formData={payment}
              setFormData={setPaymentListData}
              position={position}
              isList={true}
              calcWidth={calcWidth}
              type={payment.status !== FinancingStatusType.PENDING ? AdminInputType.VIEW : AdminInputType.TEXT}
            />

            {/*  TODO: trazer da api */}
            <AdminDropdown
              name={PaymentFormProperties.BANK}
              required={true}
              htmlFor={PaymentFormProperties.BANK}
              label={t('dashboard.bank')}
              formData={payment}
              setFormData={setPaymentListData}
              position={position}
              isList={true}
              list={[]}
              calcWidth={calcWidth}
              type={AdminInputType.TEXT}
            />

            <AdminInput
              name={PaymentFormProperties.AGENCY}
              required={true}
              htmlFor={PaymentFormProperties.AGENCY}
              label={t('dashboard.agency')}
              styled={{ borderRadius: true, borderRight: true }}
              formData={payment}
              setFormData={setPaymentListData}
              position={position}
              isList={true}
              calcWidth={calcWidth}
              type={payment.status !== FinancingStatusType.PENDING ? AdminInputType.VIEW : AdminInputType.TEXT}
            />
            <AdminInput
              name={PaymentFormProperties.BANK_ACCOUNT}
              required={true}
              htmlFor={PaymentFormProperties.BANK_ACCOUNT}
              label={t('admin.procedureScreen.details.bankAccount')}
              styled={{ borderRadius: true, borderRight: true }}
              formData={payment}
              setFormData={setPaymentListData}
              position={position}
              isList={true}
              calcWidth={calcWidth}
              type={payment.status !== FinancingStatusType.PENDING ? AdminInputType.VIEW : AdminInputType.TEXT}
            />
          </>
        );
      case PaymentMethod.BANK_SLIP:
        return (
          <>
            <AdminInput
              name={PaymentFormProperties.COD_BANK_SLIP}
              required={true}
              htmlFor={PaymentFormProperties.COD_BANK_SLIP}
              label={t('admin.procedureScreen.payments.bankSlipCode')}
              styled={{ borderRadius: true, borderRight: true }}
              formData={payment}
              setFormData={setPaymentListData}
              position={position}
              isList={true}
              calcWidth={calcWidth}
              type={payment.status !== FinancingStatusType.PENDING ? AdminInputType.VIEW : AdminInputType.TEXT}
            />
          </>
        );
      case PaymentMethod.PIX:
        return (
          <>
            <AdminDropdown
              name={PaymentFormProperties.PIX_TYPE}
              required={true}
              htmlFor={PaymentFormProperties.PIX_TYPE}
              label={t('admin.procedureScreen.payments.keyType.placeholder')}
              formData={payment}
              setFormData={setPaymentListData}
              position={position}
              isList={true}
              list={pixOptions.map(item => item.label)}
              objectList={pixOptions}
              calcWidth={calcWidth}
              type={payment.status !== FinancingStatusType.PENDING ? AdminInputType.VIEW : AdminInputType.TEXT}
            />
            <AdminInput
              name={PaymentFormProperties.PIX_KEY}
              required={true}
              htmlFor={PaymentFormProperties.PIX_KEY}
              label={t('enums.campNames.pixKey')}
              styled={{ borderRadius: true, borderRight: true }}
              formData={payment}
              setFormData={setPaymentListData}
              position={position}
              isList={true}
              calcWidth={calcWidth}
              type={payment.status !== FinancingStatusType.PENDING ? AdminInputType.VIEW : AdminInputType.TEXT}
              onBlur={() => methods.trigger(PaymentFormProperties.PIX_KEY)}
              mask={val => {
                if (payment.pixTypeObject?.value) {
                  return MaskPixFormats[payment.pixTypeObject?.value](val);
                } else {
                  return val;
                }
              }}
            />
          </>
        );
    }
  };

  const onBlur = e => {
    methods.setValue(
      PaymentFormProperties.VALUE,
      StringUtils.currencyMask(StringUtils.verifyMinAndMaxValue(e.target.value, 0, maxPaymentValue))
    );
    setPaymentListData?.(prevListaObjetos => {
      const updatedObjectList = [...prevListaObjetos];
      updatedObjectList[position ?? 0] = {
        ...updatedObjectList[position ?? 0],
        [PaymentFormProperties.VALUE]: StringUtils.currencyMask(StringUtils.verifyMinAndMaxValue(e.target.value, 0, maxPaymentValue)),
      };
      return updatedObjectList;
    });
  };

  const isStatusDone = payment.status?.toLowerCase() === FinancingStatusType.DONE.toLowerCase();
  const verifySectionType = () => {
    switch (sectionType) {
      case PaymentSectionType.REIMBURSEMENT:
        return (
          <FormProvider {...methods}>
            <DynamicForm onSubmit={methods.handleSubmit(handleForm)} id={`paymentSubmit${position}`} autoComplete="off">
              <AdminInput
                name={PaymentFormProperties.VALUE}
                required={true}
                htmlFor={PaymentFormProperties.VALUE}
                label={t('admin.procedureScreen.payments.paymentValue')}
                styled={{ borderRadius: true, borderRight: true }}
                formData={payment}
                setFormData={setPaymentListData}
                position={position}
                isList={true}
                calcWidth={calcWidth}
                mask={StringUtils.currencyMask}
                type={payment.status !== FinancingStatusType.PENDING ? AdminInputType.VIEW : AdminInputType.TEXT}
              />
              <AdminInput
                name={PaymentFormProperties.STATUS}
                required={true}
                htmlFor={PaymentFormProperties.STATUS}
                label={t('admin.procedureScreen.payments.paymentStatus')}
                formData={payment}
                setFormData={setPaymentListData}
                position={position}
                isList={true}
                calcWidth={calcWidth}
                type={AdminInputType.STATUS}
                status={payment.status}
                statusColor={payment.status}
              />
              <UploadComponent
                name={PaymentFormProperties.ATTACHMENTS}
                label={t('global.fields.bankSlip')}
                required={true}
                formData={payment}
                setFormData={setPaymentListData}
                position={position}
                type={payment.status !== FinancingStatusType.PENDING ? AdminInputType.VIEW : AdminInputType.UPLOAD}
              />
            </DynamicForm>
          </FormProvider>
        );

      default:
        return (
          <FormProvider {...methods}>
            <DynamicForm onSubmit={methods.handleSubmit(handleForm)} id={`paymentSubmit${position}`} autoComplete="off">
              <AdminInput
                name={PaymentFormProperties.VALUE}
                required={true}
                htmlFor={PaymentFormProperties.VALUE}
                label={t('admin.procedureScreen.payments.paymentValue')}
                styled={{ borderRadius: true, borderRight: true }}
                formData={payment}
                setFormData={setPaymentListData}
                isList={true}
                position={position}
                calcWidth={calcWidth}
                mask={StringUtils.currencyMask}
                onBlur={onBlur}
                type={payment.status !== FinancingStatusType.PENDING ? AdminInputType.VIEW : AdminInputType.TEXT}
              />

              <AdminDropdown
                name={PaymentFormProperties.PROCEDURE_PAYMENT_TYPE}
                required={true}
                htmlFor={PaymentFormProperties.PROCEDURE_PAYMENT_TYPE}
                label={t('admin.procedureScreen.payments.paymentMethod')}
                formData={payment}
                setFormData={setPaymentListData}
                position={position}
                isList={true}
                list={verifyPaymentMethod()}
                objectList={paymentOptions}
                calcWidth={calcWidth}
                type={AdminInputType.TEXT}
                defaultValue={paymentTypeDefaultValue}
              />

              {renderInput()}
              {pixKeyOptions.length > 0 && payment?.procedurePaymentTypeObject?.value === PaymentMethod.REGISTERED_PIX && (
                <>
                  <AdminDropdown
                    name={PaymentFormProperties.PIX_TYPE}
                    required={true}
                    htmlFor={PaymentFormProperties.PIX_TYPE}
                    label={t('admin.procedureScreen.payments.keyType.placeholder')}
                    formData={payment}
                    setFormData={setPaymentListData}
                    position={position}
                    isList={true}
                    list={pixOptions
                      .filter(item =>
                        procedure?.organizationSubsidiary?.pixRegistrations?.map(option => option.pixType).includes(item.value)
                      )
                      .map(item => item.label)}
                    objectList={pixOptions}
                    calcWidth={calcWidth}
                    type={payment.status !== FinancingStatusType.PENDING ? AdminInputType.VIEW : AdminInputType.TEXT}
                  />
                </>
              )}
              {pixKeyOptions.length > 0 &&
                payment?.procedurePaymentTypeObject?.value === PaymentMethod.REGISTERED_PIX &&
                payment.pixType &&
                renderDropDown()}
              <AdminInput
                name={PaymentFormProperties.STATUS}
                required={true}
                htmlFor={PaymentFormProperties.STATUS}
                label={t('admin.procedureScreen.payments.paymentStatus')}
                formData={payment}
                setFormData={setPaymentListData}
                position={position}
                isList={true}
                calcWidth={calcWidth}
                type={AdminInputType.STATUS}
                status={payment.status}
                statusColor={payment.status}
              />
              {isStatusDone && (
                <>
                  <AdminInput
                    name={PaymentFormProperties.CREATED_AT}
                    required={true}
                    htmlFor={PaymentFormProperties.CREATED_AT}
                    label={t('admin.procedureScreen.payments.payday')}
                    styled={{ borderRadius: true, borderRight: true }}
                    formData={payment}
                    setFormData={setPaymentListData}
                    position={position}
                    isList={true}
                    calcWidth={calcWidth}
                    type={AdminInputType.VIEW}
                    mask={formatDateToLocalFormat}
                  />
                  {payment.receiptUrl && (
                    <StyledInputBox $calcWidth="88px">
                      <StyledLabel> {t('proceduresDashboard.receiptUrl')}</StyledLabel>
                      <UploadedBox>
                        <UploadedImage>
                          <ImAttachment />
                        </UploadedImage>
                        <UploadedFileName>
                          <UploadedFileLink
                            href={payment.receiptUrl}
                            download={`${t('proceduresDashboard.fileName')}${payment.createdAt}`}
                            target="_blank"
                          >
                            {t('proceduresDashboard.receiptUrl')}
                          </UploadedFileLink>
                        </UploadedFileName>
                      </UploadedBox>
                    </StyledInputBox>
                  )}
                  {payment.receipt && (
                    <StyledInputBox $calcWidth="88px">
                      <StyledLabel> {t('proceduresDashboard.receiptUrl')}</StyledLabel>
                      <UploadedBox>
                        <UploadedImage>
                          <ImAttachment />
                        </UploadedImage>
                        <UploadedFileName>
                          <UploadedFileLink
                            href={payment.receipt?.presignedUrl}
                            download={`${t('proceduresDashboard.fileName')}${payment.createdAt}`}
                            target="_blank"
                          >
                            {t('proceduresDashboard.receiptUrl')}
                          </UploadedFileLink>
                        </UploadedFileName>
                      </UploadedBox>
                    </StyledInputBox>
                  )}
                </>
              )}
            </DynamicForm>
          </FormProvider>
        );
    }
  };

  return (
    <PaymentContainer $isOpen={isOpen}>
      <Snackbar open={openAlert} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>
      <PaymentHeader>
        {!isOpen ? (
          <PaymentClosedHeader>
            <PaymentTitle>
              {!isDone && (
                <ConfirmAction
                  onConfirm={onDelete}
                  isLoading={isLoading}
                  title={t('proceduresDashboard.deletePayment')}
                  text={t('proceduresDashboard.confirmDelete')}
                  activateButton={
                    <DeletePayment title={t('proceduresDashboard.deletePaymentIcon')}>
                      <RiDeleteBin6Line />
                    </DeletePayment>
                  }
                />
              )}

              {title}
            </PaymentTitle>
            <Separate />
            <PaymentValue>{payment?.value ? StringUtils.currencyMask(payment?.value) : StringUtils.currencyPtBrMask(0)}</PaymentValue>
            <Separate />
            <Status text={t(`enum.financingStatus.${payment.status}`)} color={payment.status ?? ''} />
            {errorPaymentMessage}
          </PaymentClosedHeader>
        ) : (
          <PaymentClosedHeader>
            <PaymentTitle>
              {!isDone && (
                <ConfirmAction
                  onConfirm={onDelete}
                  isLoading={isLoading}
                  title={t('proceduresDashboard.deletePayment')}
                  text={t('proceduresDashboard.confirmDelete')}
                  activateButton={
                    <DeletePayment title={t('proceduresDashboard.deletePaymentIcon')}>
                      <RiDeleteBin6Line />
                    </DeletePayment>
                  }
                />
              )}
              {title}
            </PaymentTitle>
          </PaymentClosedHeader>
        )}
        <PyamentClosedButtonBox>
          {!isDone && (
            <>
              <ConfirmAction
                isSubmit
                isLoading={isLoading}
                title={t('proceduresDashboard.confirmPayment')}
                text={t('proceduresDashboard.clickToConfirmDelete')}
                formId={`paymentSubmit${position}`}
                modalRef={modalRef}
                type={payment.status}
                activateButton={
                  <ButtonPayment noMargin={true} calcWidth="-46px">
                    {buttonPayment}
                  </ButtonPayment>
                }
              />
              <Separate />
            </>
          )}
          {<SeeDetails onClick={() => setIsOpen(!isOpen)}>{t('proceduresDashboard.seeDetails')}</SeeDetails>}
        </PyamentClosedButtonBox>
      </PaymentHeader>
      {isOpen && verifySectionType()}
    </PaymentContainer>
  );
};

export default PaymentSection;
