import { ORGANIZATION_TYPE } from 'config/constants';
import AdminFiltersContext from 'context/admin-filters';
import _ from 'lodash';
import { FinancingDashboard } from 'model/dashboard';
import { CreatedDates } from 'model/date';
import { FinancingStatus } from 'model/enums/financing-status';
import { OrganizationType } from 'model/enums/organization-type';
import { useAdmin } from 'provider/admin-general';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DashboardService } from 'services/dashboard-service';
import { formatDateToLocalFormat } from 'shared/util/date-utils';
import { getStorageFilter } from 'shared/util/filter-utils';
import StringUtils from 'shared/util/string-utils';
import { Status } from '../../components/status';
import { TableId, TableList, TableValues } from '../../components/table-list';
import { TitleHeader } from '../../components/title-header';

const filterValues = [
  FinancingStatus.NEW,
  FinancingStatus.IN_PROGRESS,
  FinancingStatus.VALIDATION,
  FinancingStatus.PENDING_PAYMENT,
  FinancingStatus.PENDING_SIGN,
  FinancingStatus.ADJUST,
  FinancingStatus.ADJUSTED,
  FinancingStatus.APPROVED,
  FinancingStatus.FINISHED,
  FinancingStatus.REJECTED,
  FinancingStatus.CANCELLED,
];

export const CreditAnalysisList = () => {
  const [financings, setFinancings] = useState<FinancingDashboard[]>([]);
  const [searchName, setSearchName] = useState('');
  const [searchSubsidiaryName, setSearchSubsidiaryName] = useState('');
  const [searchIdentification, setSearchIdentification] = useState('');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [orderBy, setOrderBy] = useState<boolean>(false);
  const { t } = useTranslation();
  const { statusFilters, createdDates, handleSetNewStatus } = useAdmin();
  const history = useHistory();
  const storageFilters = getStorageFilter();
  const isPhysicalPerson = ORGANIZATION_TYPE === OrganizationType.PF;

  const { filters } = useContext(AdminFiltersContext);

  useEffect(() => {
    verifyStorageAndGetFinancings();
  }, [searchName, searchIdentification, page, orderBy, searchSubsidiaryName, filters]);

  const verifyStorageAndGetFinancings = () => {
    let defaultFilters = statusFilters;

    if (storageFilters?.statusFilters && storageFilters?.statusFilters?.length > 0 && !statusFilters.length) {
      defaultFilters = storageFilters.statusFilters;
      handleSetNewStatus(defaultFilters);
    }

    getDashboardFinancings({
      name: searchName,
      page,
      financingStatus: defaultFilters,
      createdDates,
      identification: searchIdentification,
      subsidiaryName: searchSubsidiaryName,
    });
  };

  const getDashboardFinancings = async (props?: {
    name?: string;
    size?: number;
    page?: number;
    financingStatus?: string[];
    createdDates?: CreatedDates;
    identification?: string;
    subsidiaryName?: string;
  }) => {
    const res = await DashboardService().getFinancingsList(
      props?.name,
      props?.size,
      props?.page,
      props?.financingStatus,
      props?.createdDates,
      props?.identification,
      orderBy,
      props?.subsidiaryName
    );
    setFinancings(res.content);
    setPage(res.pageable.pageNumber);
    setTotalPages(res.totalPages);
  };

  const setTableValues = () => {
    const tableValues: TableValues[] = [
      {
        header: isPhysicalPerson ? t('admin.dashboard.tableHeaders.name') : t('admin.dashboard.tableHeaders.corporateName'),
        values: isPhysicalPerson
          ? financings.map(financing => financing.client.physicalPerson?.name ?? '')
          : financings.map(financing => financing.client.legalPerson?.corporateName ?? ''),
        ids: financings.map(financing => ({ first: financing.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/creditos/analise/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.identification'),
        values: financings.map(financing => financing.identification ?? ''),
        ids: financings.map(financing => ({ first: financing.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/creditos/analise/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.ccb'),
        values: financings.map(financing => financing?.ccb ?? 'N/A'),
        ids: financings.map(financing => ({ first: financing.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/creditos/analise/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.choosedValue'),
        values: financings.map(financing => StringUtils.moneyMaskPtbr(financing.choosedValue)),
        ids: financings.map(financing => ({ first: financing.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/creditos/analise/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.createdDate'),
        values: financings.map(financing => formatDateToLocalFormat(financing.createdDate)),
        ids: financings.map(financing => ({ first: financing.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/creditos/analise/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.lastModifiedDate'),
        values: financings.map(financing => formatDateToLocalFormat(financing.lastModifiedDate)),
        ids: financings.map(financing => ({ first: financing.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/creditos/analise/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.status'),
        values: financings.map(financing => (
          <Status key={financing.id} text={t(`enum.financingStatus.${financing.financingStatus}`)} color={financing.financingStatus} />
        )),
        ids: financings.map(financing => ({ first: financing.id })),
        onClick: (ids?: TableId) => {
          if (ids) {
            history.push(`/admin/creditos/analise/${ids.first}`);
          }
        },
        styleRow: { height: '30px' },
      },
    ];

    return tableValues;
  };
  const updateSearchName = _.debounce((name: string) => {
    const isIdentification = /\d/g.test(name);

    if (isIdentification) {
      setSearchIdentification(name);
      setSearchName('');
    } else {
      setSearchName(name);
      setSearchIdentification('');
    }
  }, 500);

  const updateSubsidiaryName = _.debounce((name: string) => {
    setSearchSubsidiaryName(name);
  }, 500);

  const setNewPage = _.debounce((newPage: number) => {
    if (newPage >= totalPages) {
      return setPage(page);
    }
    setPage(newPage);
  }, 500);

  const setNewFilter = () => {
    getDashboardFinancings({ name: searchName, financingStatus: statusFilters, createdDates, identification: searchIdentification });
  };

  const cleanAppliedFilters = () => {
    getDashboardFinancings({ name: searchName, identification: searchIdentification });
  };

  return (
    <>
      <TitleHeader
        title={t('admin.dashboard.creditReviews')}
        setSearch={updateSearchName}
        setSubsidiarySearch={updateSubsidiaryName}
        filterValues={filterValues}
        setFilters={setNewFilter}
        cleanFilters={cleanAppliedFilters}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />

      <TableList tableValues={setTableValues()} page={page} totalPages={totalPages} setNewPage={setNewPage} />
    </>
  );
};
