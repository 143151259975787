import { ORGANIZATION_TYPE } from 'config/constants';
import AdminFiltersContext from 'context/admin-filters';
import _ from 'lodash';
import { PaymentDashboard } from 'model/dashboard';
import { CreatedDates } from 'model/date';
import { OrganizationType } from 'model/enums/organization-type';
import { PaymentStatus } from 'model/enums/payment-status';
import { useAdmin } from 'provider/admin-general';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { DashboardService } from 'services/dashboard-service';
import { getStorageFilter } from 'shared/util/filter-utils';
import StringUtils from 'shared/util/string-utils';
import { Status } from '../../components/status';
import { TableId, TableList, TableValues } from '../../components/table-list';
import { TitleHeader } from '../../components/title-header';

const filterValues = [PaymentStatus.NEW, PaymentStatus.IN_PROGRESS, PaymentStatus.FINISHED, PaymentStatus.CANCELLED, PaymentStatus.OVERDUE];

export const PaymentsList = () => {
  const [payments, setPayments] = useState<PaymentDashboard[]>([]);
  const [searchName, setSearchName] = useState('');
  const [searchSubsidiaryName, setSearchSubsidiaryName] = useState('');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const { t } = useTranslation();
  const { statusFilters, createdDates, handleSetNewStatus } = useAdmin();
  const history = useHistory();
  const storageFilters = getStorageFilter();
  const isPhysicalPerson = ORGANIZATION_TYPE === OrganizationType.PF;
  const [orderBy, setOrderBy] = useState<boolean>(false);

  const { filters } = useContext(AdminFiltersContext);

  useEffect(() => {
    verifyStorageAndGetPayments();
  }, [searchName, page, searchSubsidiaryName, filters]);

  const verifyStorageAndGetPayments = () => {
    let defaultFilters = statusFilters;

    if (storageFilters?.statusFilters && storageFilters?.statusFilters?.length > 0 && !statusFilters.length) {
      defaultFilters = storageFilters.statusFilters;
      handleSetNewStatus(defaultFilters);
    }

    getDashboardPayments({ name: searchName, page, paymentStatus: defaultFilters, createdDates, subsidiaryName: searchSubsidiaryName });
  };

  const getDashboardPayments = async (props?: {
    name?: string;
    size?: number;
    page?: number;
    paymentStatus?: string[];
    createdDates?: CreatedDates;
    subsidiaryName?: string;
  }) => {
    const res = await DashboardService().getPaymentsList(
      props?.name,
      props?.size,
      props?.page,
      props?.paymentStatus,
      props?.createdDates,
      orderBy,
      props?.subsidiaryName
    );
    setPayments(res.content);
    setPage(res.pageable.pageNumber);
    setTotalPages(res.totalPages);
  };

  const setTableValues = () => {
    const tableValues: TableValues[] = [
      {
        header: isPhysicalPerson ? t('admin.dashboard.tableHeaders.name') : t('admin.dashboard.tableHeaders.corporateName'),
        values: isPhysicalPerson
          ? payments.map(payment => payment.financing.client.physicalPerson?.name ?? '')
          : payments.map(contract => contract.financing.client.legalPerson?.corporateName ?? ''),
        ids: payments.map(payment => ({
          first: payment.financing.id,
        })),
        onClick: (ids?: TableId) => {
          if (ids?.first) {
            history.push(`/admin/pagamentos/analise/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.ccb'),
        values: payments.map(payment => payment.financing?.ccb ?? 'N/A'),
        ids: payments.map(payment => ({
          first: payment.financing.id,
        })),
        onClick: (ids?: TableId) => {
          if (ids?.first) {
            history.push(`/admin/pagamentos/analise/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.value'),
        values: payments.map(payment => StringUtils.moneyMaskPtbr(payment.totalValue)),
        ids: payments.map(payment => ({
          first: payment.financing.id,
        })),
        onClick: (ids?: TableId) => {
          if (ids?.first) {
            history.push(`/admin/pagamentos/analise/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.nextDueDate'),
        values: payments.map(payment => StringUtils.dateFormatMask(payment.nextDueDate ?? '')),
        ids: payments.map(payment => ({
          first: payment.financing.id,
        })),
        onClick: (ids?: TableId) => {
          if (ids?.first) {
            history.push(`/admin/pagamentos/analise/${ids.first}`);
          }
        },
      },
      {
        header: t('admin.dashboard.tableHeaders.status'),
        values: payments.map(payment => (
          <div style={{ display: 'flex', alignItems: 'center', height: '24px' }}>
            <Status key={payment.id} text={t(`enum.financingStatus.${payment.status}`)} color={payment.status} />
          </div>
        )),
        ids: payments.map(payment => ({
          first: payment.financing.id,
        })),
        onClick: (ids?: TableId) => {
          if (ids?.first) {
            history.push(`/admin/pagamentos/analise/${ids.first}`);
          }
        },
      },
    ];

    return tableValues;
  };

  const updateSearchName = _.debounce((name: string) => {
    setSearchName(name);
  }, 500);

  const updateSubsidiaryName = _.debounce((name: string) => {
    setSearchSubsidiaryName(name);
  }, 500);

  const setNewPage = _.debounce((newPage: number) => {
    if (newPage >= totalPages) {
      return setPage(page);
    }
    setPage(newPage);
  }, 500);

  const setNewFilter = () => {
    getDashboardPayments({ name: searchName, paymentStatus: statusFilters, createdDates });
  };

  const cleanAppliedFilters = () => {
    getDashboardPayments({ name: searchName });
  };

  return (
    <>
      <TitleHeader
        title={t('admin.dashboard.payments')}
        setSearch={updateSearchName}
        setSubsidiarySearch={updateSubsidiaryName}
        filterValues={filterValues}
        setFilters={setNewFilter}
        cleanFilters={cleanAppliedFilters}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
      <TableList tableValues={setTableValues()} page={page} totalPages={totalPages} setNewPage={setNewPage} />
    </>
  );
};
