export enum FinancingStatusBalance {
  GENERATING_PAYMENT = 'GENERATING_PAYMENT',
  WAITING_PAYMENT = 'WAITING_PAYMENT',
  TRANSFER_BMP = 'TRANSFER_BMP',
  TRANSFER_NELLPAY_IUGU = 'TRANSFER_NELLPAY_IUGU',
  TRANSFER_NELLPAY_IUGU_CONFIRMATION = 'TRANSFER_NELLPAY_IUGU_CONFIRMATION',
  TRANSFER_NELLPAY_GIRO = 'TRANSFER_NELLPAY_GIRO',
  TRANSFER_NELLPAY_GIRO_CONFIRMATION = 'TRANSFER_NELLPAY_GIRO_CONFIRMATION',
  FINISHED = 'FINISHED',
}
