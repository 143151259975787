import { useAccountInfo } from 'hooks/use-account-info';
import { useAuthorities } from 'hooks/use-authorities';
import { Authority } from 'model/enums/authority';

export const useInsurance = () => {
  const { hasAuthorities } = useAuthorities();
  const { account } = useAccountInfo();

  const isAdmin = hasAuthorities([Authority.ROLE_ADMIN, Authority.ROLE_INTERNAL_ADMIN]);
  const isFromSubsidiaryWithInsurance = account?.internal?.organizationSubsidiary?.name?.toLocaleLowerCase().includes('filial 39') ?? false;
  const hasInsurance: boolean = isFromSubsidiaryWithInsurance;

  return {
    hasInsurance,
  };
};
