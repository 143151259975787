import { useDefaultSendFinancingDataHandler } from './default';
import { FinancingSimpleSimulation } from 'model/financing';
import { SimulatorLimit } from 'context/simulator';

export interface ProcessingSimulatorData {
  simulatorData?: FinancingSimpleSimulation;
  simulatorLimits?: SimulatorLimit;
  parameters: {
    isNewCredit: boolean;
    isAdmin: boolean;
  };
  redirect?: {
    approved?: boolean;
    confirmSimulation?: boolean;
    withoutLimit?: boolean;
  };
}

export interface ProcessingSimulatorHandler {
  execute: (data: ProcessingSimulatorData) => Promise<ProcessingSimulatorData>;
}

export const useSendFinancingData = () => {
  return useDefaultSendFinancingDataHandler();
};
