export enum StepsType {
  SLIDER = 'SLIDER',
  FORM = 'FORM',
  SECONDARY_FORM = 'SECONDARY_FORM',
  OUT_OF_TIME = 'OUT_OF_TIME',
}

export enum SimulatorType {
  DEFAULT = 'DEFAULT',
  OUT_OF_TIME = 'OUT_OF_TIME',
  OUT_OF_ZONE = 'OUT_OF_ZONE',
  HAS_REGISTRATION = 'HAS_REGISTRATION',
}
