import { ContainerInputsAddressDataMulttiplo } from 'components/edit-forms/address-multtiplo/inputs-address';
import { ContainerInputsAddress } from 'components/edit-forms/address/inputs-address';
import { ResumeScreen } from 'components/edit-forms/documents/screens/resume';
import { EditSelfieScreen } from 'components/edit-forms/documents/screens/selfie-edit';
import { ContainerInputsIncome } from 'components/edit-forms/income/inputs-income';
import { ContainerInputsPersonalDataMulttiplo } from 'components/edit-forms/personal-data-multtiplo/inputs-personal-data';
import { ContainerInputsPersonalData } from 'components/edit-forms/personal-data/inputs-personal-data';
import SystemStepCategory from 'model/enums/system-step-category';
import TypeSubStepCategory from 'model/enums/type-substep-category';
import OrganizationsSystemSteps from 'model/organization-system-steps';
import { useMemo } from 'react';

export interface StepEditForm {
  step: OrganizationsSystemSteps;
  form: JSX.Element;
}

interface EditStepsProps {
  steps: OrganizationsSystemSteps[];
  onlyView: boolean;
  visible?: SystemStepCategory | TypeSubStepCategory;
  checkIfIsRequired?: (employmentBond: string) => boolean;
}

export const EditFormSteps = ({ steps, onlyView, visible, checkIfIsRequired }: EditStepsProps): JSX.Element => {
  const elements: JSX.Element[] = useMemo(() => {
    return steps.map((step: OrganizationsSystemSteps) => {
      switch (step.systemStep?.step) {
        case SystemStepCategory.PERSONAL_DATA:
          return <ContainerInputsPersonalData onlyView={onlyView} visible={visible === SystemStepCategory.PERSONAL_DATA} />;
        case SystemStepCategory.PERSONAL_DATA_MULTTIPLO:
          return (
            <ContainerInputsPersonalDataMulttiplo onlyView={onlyView} visible={visible === SystemStepCategory.PERSONAL_DATA_MULTTIPLO} />
          );
        case SystemStepCategory.ADDRESS:
          return <ContainerInputsAddress onlyView={onlyView} visible={visible === SystemStepCategory.ADDRESS} />;
        case SystemStepCategory.ADDRESS_MULTTIPLO:
          return <ContainerInputsAddressDataMulttiplo onlyView={onlyView} visible={visible === SystemStepCategory.ADDRESS_MULTTIPLO} />;

        case SystemStepCategory.SELFIE:
          return <EditSelfieScreen onlyView={onlyView} visible={visible === SystemStepCategory.SELFIE} />;

        case SystemStepCategory.PHYSICAL_PERSON_DOCUMENTS:
          return <ResumeScreen onlyView={onlyView} visible={visible === SystemStepCategory.PHYSICAL_PERSON_DOCUMENTS} />;
        case SystemStepCategory.PHYSICAL_PERSON_IDENTIFICATION_DOCUMENT:
          return (
            <ResumeScreen
              onlyView={onlyView}
              visible={visible === SystemStepCategory.PHYSICAL_PERSON_IDENTIFICATION_DOCUMENT}
              isIdentificationDocumentOnly
            />
          );
        case SystemStepCategory.INCOME:
          return <ContainerInputsIncome onlyView={onlyView} visible={visible === SystemStepCategory.INCOME} />;
        default:
          return <></>;
      }
    });
  }, [steps, visible]);
  return <>{elements}</>;
};
