import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { hasAuthorities } from 'shared/util/utils';
import { Button, ButtonContainer, Container, FilterContainer, FilterContent, FilterItem, FilterTagContainer, Title } from './styles';
import SearchInput from 'features/admin/dashboard/components/search-input';
import { SearchInputOptions } from 'features/admin/dashboard/components/search-input-options';
import { OrderFilterButton } from 'features/admin/dashboard/components/filter-button-order';
import { StatusFilterButton } from 'features/admin/dashboard/components/filter-button-status';
import { StyledTitleContainer } from 'features/admin/dashboard/components/title-header/styles';
import PeriodFilterCalendar from '../period-filter-calendar';
import { useAdmin } from 'provider/admin-general';
import FilterTag from 'components/filter-tag';
import { formatDateToLocalFormat } from 'shared/util/date-utils';
import { Myaccounthistories } from 'model/enums/my-account';

interface TitleHeaderProps {
  selectedStep?: Myaccounthistories;
  setSearch?: (value: string) => void;
  setSearchOption?: (value: string) => void;
  searchOption?: string;
  setFilters?: () => void;
  cleanFilters: () => void;
  setOrderBy?: (value: boolean) => void;
  filterStatusValues?: string[];
  orderBy?: boolean;
}

export const TitleHeader = ({
  selectedStep,
  setSearch,
  setSearchOption,
  searchOption,
  setFilters,
  filterStatusValues,
  cleanFilters,
}: TitleHeaderProps) => {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<[Date | null, Date | null]>();

  const { statusFilters, handleSetCreatedDates, resetCreatedDates, handleSetNewStatus } = useAdmin();

  const account = useSelector((state: IRootState) => state.authentication.account);

  const renderSearchButton = () => {
    return (
      <SearchInput
        title={
          selectedStep === Myaccounthistories.HISTORYOFTRANSACTIONS
            ? t('admin.dashboard.searchCCB')
            : t('admin.dashboard.searchByBalanceSolicitation')
        }
        setSearch={setSearch}
      />
    );
  };

  useEffect(() => {
    if (dateRange) {
      handleSetCreatedDates({
        lte: dateRange[1]?.toISOString().split('T')[0],
        gte: dateRange[0]?.toISOString().split('T')[0],
      });
    }
  }, [dateRange]);

  const renderContent = () => {
    return (
      <>
        <FilterContainer>
          <FilterContent>
            <StatusFilterButton
              filterStatus={filterStatusValues ? filterStatusValues : []}
              onAppliedFilters={() => {
                if (setFilters) {
                  setFilters();
                }
              }}
              onCleanFilter={() => {
                if (cleanFilters) {
                  cleanFilters();
                }
                setDateRange(undefined);
              }}
            />
            <PeriodFilterCalendar
              placeholder={t('admin.dashboard.period')}
              onChangeValue={date => date !== null && setDateRange(date)}
              isActive={false}
              date={dateRange}
              onCleanFilter={() => {
                if (cleanFilters) {
                  cleanFilters();
                }
              }}
            />
          </FilterContent>
          <ButtonContainer>{renderSearchButton()}</ButtonContainer>
        </FilterContainer>
      </>
    );
  };
  return (
    <Container $changeLayout>
      {renderContent()}
      {statusFilters.length > 0 && (
        <FilterTagContainer>
          {statusFilters.map((status, idx) => {
            return (
              <FilterTag
                key={`tag-${status}-${idx}`}
                text={t(`enum.filterAllStatus.${status}`)}
                onClick={() => handleSetNewStatus(statusFilters.filter(filter => filter !== status))}
              />
            );
          })}
          <Button onClick={() => cleanFilters()}>{t('reportScreen.filter.clearFilters')}</Button>
        </FilterTagContainer>
      )}
    </Container>
  );
};
