import SystemStepCategory from 'model/enums/system-step-category';

export const editFormFields = {
  [SystemStepCategory.PERSONAL_DATA]: ['name', 'birth', 'phone', 'email', 'cpf', 'rg'],
  [SystemStepCategory.PERSONAL_DATA_MULTTIPLO]: ['name', 'birth', 'phone', 'email', 'cpf', 'rg'],
  [SystemStepCategory.ADDRESS]: ['zipcode', 'street', 'number', 'complement', 'district', 'state', 'city'],
  [SystemStepCategory.INCOME]: ['occupation', 'income', 'companyTimeInYears'],
  [SystemStepCategory.PHYSICAL_PERSON_DOCUMENTS]: [],
  [SystemStepCategory.PHYSICAL_PERSON_IDENTIFICATION_DOCUMENT]: [],
  [SystemStepCategory.SELFIE]: [],
};
