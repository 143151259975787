import { Step, StepIcon, Stepper } from '@mui/material';
import { WithTranslation, withTranslation } from 'react-i18next';
import CustomStepIcon from '../custom-step-icon';
import * as S from './styles';
import { Fragment } from 'react';
import useCustomStepper from './hooks/use-custom-stepper';

interface Props extends WithTranslation {
  currentStep: number;
  paymentCompanyName?: string;
}

const CustomStepper = ({ currentStep, paymentCompanyName, t }: Props) => {
  const { stepNames } = useCustomStepper(paymentCompanyName === 'BMP' ? true : false);

  return (
    <S.StepsContainer>
      <S.StepsTitle>{t('admin.newBalance.steps.title')}</S.StepsTitle>
      <Stepper connector={null}>
        {stepNames.map((label, index) => {
          const isStepCompleted = index < currentStep;
          const isNotLastPosition = !(index === stepNames.length - 1);

          return (
            <Fragment key={`step-${index}`}>
              <Step>
                <StepIcon
                  icon={<CustomStepIcon label={label} stepValue={index} currentStep={currentStep} stepCompleted={isStepCompleted} />}
                />
              </Step>
              {isNotLastPosition && <S.StepConnector $stepCompleted={isStepCompleted} />}
            </Fragment>
          );
        })}
      </Stepper>
    </S.StepsContainer>
  );
};

export default withTranslation()(CustomStepper);
