import landingPageApi from 'api/landing-page-api';
import { AxiosError, AxiosResponse } from 'axios';
import { ClientLP } from 'model/client';
import { CreateFinancingResponse } from 'model/create-financing-response';
import HttpStatus from 'model/enums/http-status';
import FilterParameters from 'model/filter-parameters';
import { FinancingSimpleSimulation } from 'model/financing';
import {
  OrganizationRules,
  OrganizationSubsidiaries,
  ResponseMaritalStatus,
  ResponsePreSimulator,
  ResponseResidence,
  SendPreSimulator,
  SimulatorParameters,
  SimulatorFormData,
} from 'model/landing-page';
import { GetValidationType, OrganizationData } from 'model/organization-types';

export const landingPageService = () => {
  const getData = async (): Promise<OrganizationData> => {
    try {
      const result: AxiosResponse<OrganizationData> = await landingPageApi.getData();

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getSimulatorParameters = async (): Promise<SimulatorParameters> => {
    try {
      const result: AxiosResponse<SimulatorParameters> = await landingPageApi.getSimulatorParameters();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getValidation = async (type): Promise<GetValidationType[]> => {
    try {
      const result: AxiosResponse<GetValidationType[]> = await landingPageApi.getValidation(type);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const postPhysicalPerson = async (data: ClientLP): Promise<SimulatorFormData> => {
    try {
      const result: AxiosResponse<SimulatorFormData> = await landingPageApi.postPhysicalPerson(data);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.CREATED) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const postLegalPerson = async (data: ClientLP): Promise<SimulatorFormData> => {
    try {
      const result: AxiosResponse<SimulatorFormData> = await landingPageApi.postLegalPerson(data);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.CREATED) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const postPhysicalPersonAdmin = async (data: ClientLP): Promise<SimulatorFormData> => {
    try {
      const result: AxiosResponse<SimulatorFormData> = await landingPageApi.postPhysicalPersonAdmin(data);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.CREATED) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const postLegalPersonAdmin = async (data: ClientLP): Promise<SimulatorFormData> => {
    try {
      const result: AxiosResponse<SimulatorFormData> = await landingPageApi.postLegalPersonAdmin(data);

      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.CREATED) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const postSimulator = async (data: FinancingSimpleSimulation): Promise<CreateFinancingResponse> => {
    try {
      const result: AxiosResponse<CreateFinancingResponse> = await landingPageApi.postSimulator(data);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const postSimulationDraft = async (data: FinancingSimpleSimulation): Promise<CreateFinancingResponse> => {
    try {
      const result: AxiosResponse<CreateFinancingResponse> = await landingPageApi.postSimulationDraft(data);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const checkClientMonthlyLimit = async (id?: number): Promise<boolean> => {
    try {
      const result: AxiosResponse<boolean> = await landingPageApi.checkClientMonthlyLimit(id);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getOrganizationRules = async (id?: number): Promise<OrganizationRules> => {
    try {
      const result: AxiosResponse<OrganizationRules> = await landingPageApi.getOrganizationRules(id);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getOrganizationSubsidiaries = async (filterParameters?: FilterParameters): Promise<OrganizationSubsidiaries[]> => {
    try {
      const result: AxiosResponse<OrganizationSubsidiaries[]> = await landingPageApi.getOrganizationSubsidiaries(filterParameters);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      }

      return Promise.reject({ status: result.status } as AxiosResponse);
    } catch (error) {
      const { response } = error as AxiosError;

      console.error(response);
      return Promise.reject(response);
    }
  };

  const postOrganizationSubsidiaries = async (id: number, clientId: number): Promise<any> => {
    try {
      const result: AxiosResponse<any> = await landingPageApi.postOrganizationSubsidiaries(id, clientId);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };
  const getMaritalStatus = async (): Promise<ResponseMaritalStatus> => {
    try {
      const result: AxiosResponse<ResponseMaritalStatus> = await landingPageApi.getMaritalStatus();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const getResidenceType = async (): Promise<ResponseResidence> => {
    try {
      const result: AxiosResponse<ResponseResidence> = await landingPageApi.getResidenceType();
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  const preSimulation = async (data: SendPreSimulator): Promise<ResponsePreSimulator> => {
    try {
      const result: AxiosResponse<ResponsePreSimulator> = await landingPageApi.preSimulation(data);
      if (result.status === HttpStatus.OK && result.data != null) {
        return Promise.resolve(result.data);
      } else if (result.status === HttpStatus.UNAUTHORIZED) {
        return Promise.reject({ status: 401 } as AxiosResponse);
      } else {
        return Promise.reject({ status: 400 } as AxiosResponse);
      }
    } catch (error: any) {
      console.error(error);
      return Promise.reject(error.response as AxiosResponse);
    }
  };

  return {
    getData,
    getSimulatorParameters,
    getValidation,
    postPhysicalPerson,
    postLegalPerson,
    postSimulator,
    postSimulationDraft,
    checkClientMonthlyLimit,
    getOrganizationRules,
    getOrganizationSubsidiaries: getOrganizationSubsidiaries,
    postOrganizationSubsidiaries,
    getMaritalStatus,
    getResidenceType,
    postLegalPersonAdmin,
    postPhysicalPersonAdmin,
    preSimulation,
  };
};

export default landingPageService();
